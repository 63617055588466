import React from 'react'
import { BrowserRouter,Route, Routes } from 'react-router-dom'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Home from '../pages/Home'
import NotFound404 from '../pages/NotFound404'
import ContactUs from '../pages/ContactUs'
import Portfolio from '../pages/Portfolio'
import Company from '../pages/Company'
import CloudAndDevops from '../pages/services/CloudAndDevops'
import GraphicsAndAnimation from '../pages/services/GraphicsAndAnimation'
import IOT from '../pages/services/IOT'
import MobileApplication from '../pages/services/MobileApplication'
import SoftwareDevelopment from '../pages/services/SoftwareDevelopment'
import StaffingAndOutsourcing from '../pages/services/StaffingAndOutsourcing'
import UiUx from '../pages/services/UiUx'
import WebDevelopment from '../pages/services/WebDevelopment'
import EdTech from '../pages/industries/EdTech'
import Healthcare from '../pages/industries/Healthcare'
import Hotel from '../pages/industries/Hotel'
import Manufacturing from '../pages/industries/Manufacturing'
import Realestate from '../pages/industries/Realestate'
import RetailAndEcommerce from '../pages/industries/RetailAndEcommerce'
import Travel from '../pages/industries/Travel'
import SchoolManagement from '../pages/products/SchoolManagement'
import BillingAndInventory from '../pages/products/BillingAndInventory'
import ERP from '../pages/products/ERP'
import POS from '../pages/products/POS'
import HRMSPayroll from '../pages/products/HRMSPayroll'
import HotelManagement from '../pages/products/HotelManagement'
import Demo from '../pages/Demo'
import Integrations from '../pages/Integrations'
import Test from '../pages/Test'
import Privacy from '../pages/Privacy'
import TermsConditions from '../pages/TermsConditions'
import Refund from '../pages/Refund'

export default function Routers() {
  return (
    <>
    <BrowserRouter>
        <Header/>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/home" element={<Home/>}/>
            <Route path="/webdevelopment" element={<WebDevelopment/>}/>
            <Route path="/ui-ux" element={<UiUx/>}/>
            <Route path="/graphics-animation" element={<GraphicsAndAnimation/>}/>
            <Route path="/software-development" element={<SoftwareDevelopment/>}/>
            <Route path="/mobile-application" element={<MobileApplication/>}/>
            <Route path="/cloud-devops" element={<CloudAndDevops/>}/>
            <Route path="/staffing-outsourcing" element={<StaffingAndOutsourcing/>}/>
            <Route path="/iot" element={<IOT/>}/>
            <Route path="/edtech" element={<EdTech/>}/>
            <Route path="/healthcare" element={<Healthcare/>}/>
            <Route path="/hotel" element={<Hotel/>}/>
            <Route path="/manufacturing" element={<Manufacturing/>}/>
            <Route path="/realestate" element={<Realestate/>}/>
            <Route path="/retail-ecommerce" element={<RetailAndEcommerce/>}/>
            <Route path="/travel" element={<Travel/>}/>
            <Route path="/schoolmanagement" element={<SchoolManagement/>}/>
            <Route path="/billing" element={<BillingAndInventory/>}/>
            <Route path="/erp" element={<ERP/>}/>
            <Route path="/hotel-management" element={<HotelManagement/>}/>
            <Route path="/hrmspayroll" element={<HRMSPayroll/>}/>
            <Route path="/pos" element={<POS/>}/>
            <Route path="/portfolio" element={<Portfolio/>}/>
            <Route path="/company" element={<Company/>}/>
            <Route path="/contactus" element={<ContactUs/>}/>
            <Route path="/request-demo" element={<Demo/>}/>
            <Route path="/integrations" element={<Integrations/>}/>
            <Route path="/test" element={<Test/>}/>
            <Route path="/privacy" element={<Privacy/>}/>
            <Route path="/termsconditions" element={<TermsConditions/>}/>
            <Route path="/refund" element={<Refund/>}/>
            <Route path="/*" element={<NotFound404/>}/> 

        </Routes>
        <Footer/>
    </BrowserRouter>
    </>
  )
}
