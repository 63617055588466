import React from 'react'

export default function Company() {
  return (
<div classname="main-wrapper">
  <section className="customer-review-tab pt-75 pb-3 bg-gradient text-white  position-relative z-2">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="tab-content" id="testimonial-tabContent">
            <div className="tab-pane fade active show" id="testimonial-tab-1" role="tabpanel">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-7 col-md-7">
                  <div className="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                    <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width={65} className="img-fluid mb-32" />
                    <div className="blockquote-title-review mb-4">
                      <h4 className="mb-0 h4 fw-semi-bold">Welcome to</h4>
                      <h1 className="mb-0 h1 fw-semi-bold">Fornego Innovations Lab</h1>
                      
                    </div>
                    <blockquote className="blockquote">
                      <p>Globally network long-term high-impact schemas vis-a-vis distinctive
                        e-commerce</p>
                    </blockquote>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  <div className="author-img-wrap pt-5 ps-5">
                    <div className="position-relative">
                      <img src="assets/img/innovations8.png" className="img-fluid" alt="testimonial author" /> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="testimonial-section ptb-60">
    <div className="container">
      <div className="row align-items-center justify-content-between">
        <div className="col-md-12 col-lg-12">
          <div className="section-heading">
            <h2>Progress That’s Built Around You</h2>
            <p>Progress is the experienced, trusted provider of products designed with you, our customers,
              in mind. With Progress, you can build what you need, deploy where and how you want, empower 
              your customers, then manage it all safely and securely. That way, you achieve growth even faster. 
              Because Progress is not only our name, it’s also what we want you to achieve.</p>
            <p>
              How? Our people launch into your challenges and inspirations like they’re our own because they 
              are. As proactive problem solvers, we are invested in your outcomes, helping you drive faster 
              cycles of innovation, fuel momentum and achieve your goals with confidence.
            </p>
            <blockquote className="lead">
              “That proven approach is Progress that’s built around you”
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="our-story-section pt-60 pb-120" style={{background: 'url("assets/img/shape/dot-dot-wave-shape.svg")no-repeat left bottom'}}>
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-lg-5 col-md-12 order-lg-1">
          <div className="section-heading sticky-sidebar">
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active" id="testimonial-tab-1" role="tabpanel">
                <div className="testimonial-content-wrap position-relative mb-5">
                  <img src="assets/img/clients/partner-1.png" alt="client logo" width={130} className="img-fluid mb-4" />
                  <blockquote className="lead">
                    “Our people power Progress by putting the needs of our customers first. Innovating and 
                    collaborating, we move business forward.”
                  </blockquote>
                  <div className="author-info mt-4">
                    <h4 className="mb-0">Neelam Shakya</h4>
                    <p className="mt-2 mb-0">Founder Fornego Innovations Lab</p>
                    <small>( Electronics & Communication Engineer )</small>
                  </div>
                  <img src="assets/img/testimonial/quotes.svg" alt="quotes" className="position-absolute right-0 bottom-0 z--1" />
                </div>

                <div className="testimonial-content-wrap position-relative">
                  <img src="assets/img/clients/partner-1.png" alt="client logo" width={130} className="img-fluid mb-4" />
                  <blockquote className="lead">
                    “Our people power Progress by putting the needs of our customers first. Innovating and 
                    collaborating, we move business forward.”
                  </blockquote>
                  <div className="author-info mt-4">
                    <h4 className="mb-0">Kumar Rakesh</h4>
                    <p className="mt-2 mb-0">CEO & Managing DIrector Fornego Innovations Lab</p>
                    <small>( Software Engineer )</small>
                  </div>
                  <img src="assets/img/testimonial/quotes.svg" alt="quotes" className="position-absolute right-0 bottom-0 z--1" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 order-lg-0">
          <div className="story-grid-wrapper position-relative">
            {/*animated shape start*/}
            <ul className="position-absolute animate-element parallax-element shape-service d-none d-lg-block" style={{transform: 'translate3d(0px, 0px, 0px)', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden'}}>
              <li className="layer" data-depth="0.02" style={{position: 'relative', display: 'block', left: 0, top: 0, transform: 'translate3d(4.47593px, -14.101px, 0px)', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden'}}>
                <img src="assets/img/color-shape/image-2.svg" alt="shape" className="img-fluid position-absolute color-shape-2 z-5" />
              </li>
              <li className="layer" data-depth="0.03" style={{position: 'absolute', display: 'block', left: 0, top: 0, transform: 'translate3d(6.7139px, -21.1516px, 0px)', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden'}}>
                <img src="assets/img/color-shape/feature-3.svg" alt="shape" className="img-fluid position-absolute color-shape-3" />
              </li>
            </ul>
            {/*animated shape end*/}
            <div className="story-grid rounded-custom bg-dark overflow-hidden position-relative">
              <div className="story-item bg-light border">
                <h3 className="display-5 fw-bold mb-1 text-success">550K+</h3>
                <h6 className="mb-0">Active Users</h6>
              </div>
              <div className="story-item bg-white border">
                <h3 className="display-5 fw-bold mb-1 text-primary">250+</h3>
                <h6 className="mb-0">Team Members</h6>
              </div>
              <div className="story-item bg-white border">
                <h3 className="display-5 fw-bold mb-1 text-dark">$20M+</h3>
                <h6 className="mb-0">Revenue Per/Year</h6>
              </div>
              <div className="story-item bg-light border">
                <h3 className="display-5 fw-bold mb-1 text-warning">8 Years</h3>
                <h6 className="mb-0">In Business</h6>
              </div>
              <div className="story-item bg-light border">
                <h3 className="display-5 fw-bold mb-1 text-danger">425+</h3>
                <h6 className="mb-0">Clients Worldwide</h6>
              </div>
              <div className="story-item bg-white border">
                <h3 className="display-5 fw-bold mb-1 text-primary">855+</h3>
                <h6 className="mb-0">Projects Completed</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="integration-section pb-4">
    <div className="container">
      <div className="row align-items-center justify-content-between">
        <div className="col-lg-12">
          <h1 className="pb-4">Our Company Pillars</h1>
          <img className='img-fluid' src="assets/img/company-pillars-desktop.jpg" />
        </div>
      </div>
      <div className="row align-items-center mt-3 justify-content-between">
        <div className="col-lg-4">
          <h3 className="pb-1">People</h3>
          <p>Our pride grows with our customers’ success because people power progress. We encourage contributions to the community and to social and environmental causes, embracing inclusion and diversity in every action we take.</p>
        </div>
        <div className="col-lg-4">
          <h3 className="pb-1">Proven</h3>
          <p>We lead so people can succeed. As an experienced, trusted provider, we propel business with confidence, taking people where they want to go.</p>
        </div>
        <div className="col-lg-4">
          <h3 className="pb-1">Progress</h3>
          <p>We’re in it for your outcome. By creating products that deliver faster cycles of innovation we help you achieve your goals.</p>
        </div>
      </div>
    </div>
  </section>
  {/* <section className="home-blog-section ptb-60 bg-light">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-12 col-md-12">
          <div className="section-heading text-center">
            <h2>Values: Progress PROUD</h2>
            <p>Our values drive our everyday, keeping us connected, inspired and moving forward.</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-xl-12 col-12">
            <div className="action-btns mt-3">
              <ul className="list-unstyled text-center">
                <li className="d-inline-block mb-lg-0">
                  <div className="d-flex align-items-center text-decoration-none rounded" href="#">
                    <div className="row">
                      <div className="col-lg-12 card bg-dark">
                        <img height={100} width={100} src="assets/img/icons/p.png" />
                        <div className="card">
                          <h6 className>PROGRESS<br /> collaboratively</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="d-inline-block mb-lg-0">
                  <a className="d-flex align-items-center text-decoration-none rounded" href="#"><i className="fab fa-windows pe-2" />
                    <span>Available on the <span>Google Play</span></span></a>
                </li>
                <li className="d-inline-block me-2 mb-lg-0">
                  <a className="d-flex align-items-center text-decoration-none rounded" href="#"><i className="fab fa-google-play pe-2" />
                    <span>Available on the <span>Google Play</span></span></a>
                </li>
                <li className="d-inline-block mb-lg-0">
                  <a className="d-flex align-items-center text-decoration-none rounded" href="#"><i className="fab fa-windows pe-2" />
                    <span>Available on the <span>Google Play</span></span></a>
                </li>
                <li className="d-inline-block mb-lg-0">
                  <a className="d-flex align-items-center text-decoration-none rounded" href="#"><i className="fab fa-windows pe-2" />
                    <span>Available on the <span>Google Play</span></span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-6">
          <div className="single-article rounded-custom mb-4 mb-lg-0">
            <a href="blog-single.html" className="article-img">
              <img height={50} src="assets/img/icons/p.png" alt="article" className="img-fluid" />
            </a>
            <div className="article-content p-4">
              <a href="blog-single.html">
                <h2 className="h5 article-title limit-2-line-text">Do you really understand the concept of product value?</h2>
              </a>
              <p className="limit-2-line-text">Society is fragmenting into two parallel realities. In one reality, you have infinite upside and opportunity. In the other reality, you’ll continue to see the gap between your standard of living and those at the top grow more and more.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-6">
          <div className="single-article rounded-custom mb-4 mb-lg-0">
            <a href="blog-single.html" className="article-img">
              <img height={70} src="assets/img/icons/p.png" alt="article" className="img-fluid" />
            </a>
            <div className="article-content p-4">
              <a href="blog-single.html">
                <h2 className="h5 article-title limit-2-line-text">Do you really understand the concept of product value?</h2>
              </a>
              <p className="limit-2-line-text">Society is fragmenting into two parallel realities. In one reality, you have infinite upside and opportunity. In the other reality, you’ll continue to see the gap between your standard of living and those at the top grow more and more.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="text-center mt-5">
          <a href="blog.html" className="btn btn-primary">View All Article</a>
        </div>
      </div>
    </div>
  </section> */}
  <section className="feature-section-two ptb-120 bg-light">
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-6 col-md-12">
          <div className="section-heading">
            <h4 className="h5 text-primary">Our Values</h4>
            <h2>The Core Values that Drive Everything</h2>
            <p>Quickly incubate functional channels with multidisciplinary architectures. Authoritatively
              fabricate formulate exceptional innovation.</p>
            <ul className="list-unstyled mt-5">
              <li className="d-flex align-items-start mb-4">
                <div className="icon-box bg-primary rounded me-4">
                  <i className="fas fa-bezier-curve text-white" />
                </div>
                <div className="icon-content">
                  <h3 className="h5">Pixel Perfect Design</h3>
                  <p>Progressively foster enterprise-wide systems whereas equity invested
                    web-readiness harness installed.
                  </p>
                </div>
              </li>
              <li className="d-flex align-items-start mb-4">
                <div className="icon-box bg-danger rounded me-4">
                  <i className="fas fa-fingerprint text-white" />
                </div>
                <div className="icon-content">
                  <h3 className="h5">Unique &amp; Minimal Design</h3>
                  <p>Dramatically administrate progressive metrics without error-free globally
                    simplify standardized engineer efficient strategic.
                  </p>
                </div>
              </li>
              <li className="d-flex align-items-start mb-4">
                <div className="icon-box bg-dark rounded me-4">
                  <i className="fas fa-cog text-white" />
                </div>
                <div className="icon-content">
                  <h3 className="h5">Efficiency &amp; Accountability</h3>
                  <p>Objectively transition prospective collaboration and idea-sharing without focused
                    maintain focused niche markets niches.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6 col-md-7">
          <div className="feature-img-wrap position-relative d-flex flex-column align-items-end">
            <ul className="img-overlay-list list-unstyled position-absolute">
              <li className="d-flex align-items-center bg-white rounded shadow-sm p-3">
                <i className="fas fa-check bg-primary text-white rounded-circle" />
                <h6 className="mb-0">Create a Free Account</h6>
              </li>
              <li className="d-flex align-items-center bg-white rounded shadow-sm p-3">
                <i className="fas fa-check bg-primary text-white rounded-circle" />
                <h6 className="mb-0">Install Our Tracking Pixel</h6>
              </li>
              <li className="d-flex align-items-center bg-white rounded shadow-sm p-3">
                <i className="fas fa-check bg-primary text-white rounded-circle" />
                <h6 className="mb-0">Start Tracking your Website</h6>
              </li>
            </ul>
            <img src="assets/img/feature-img3.jpg" alt="feature image" className="img-fluid rounded-custom" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="integration-section ptb-60">
    <div className="container">
      <div className="row align-items-center justify-content-between">
        <div className="col-lg-3">
          <div className="integration-list-wrap">
            <a href="integration-single.html" className="integration-1" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Your Brand Name">
              <img src="assets/img/integations/1.png" alt="integration" className="img-fluid rounded-circle" />
            </a>
            <a href="integration-single.html" className="integration-2" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Your Brand Name">
              <img src="assets/img/integations/2.png" alt="integration" className="img-fluid rounded-circle" />
            </a>
            <a href="integration-single.html" className="integration-3" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Your Brand Name">
              <img src="assets/img/integations/3.png" alt="integration" className="img-fluid rounded-circle" />
            </a>
            <a href="integration-single.html" className="integration-4" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Your Brand Name">
              <img src="assets/img/integations/4.png" alt="integration" className="img-fluid rounded-circle" />
            </a>
            <a href="integration-single.html" className="integration-5" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Your Brand Name">
              <img src="assets/img/integations/5.png" alt="integration" className="img-fluid rounded-circle" />
            </a>
            <a href="integration-single.html" className="integration-6" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Your Brand Name">
              <img src="assets/img/integations/6.png" alt="integration" className="img-fluid rounded-circle" />
            </a>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="section-heading text-center my-5 my-lg-0 my-xl-0">
            <h2>We Collaborate with Top Software Company</h2>
            <a href="portfolio.php" className="mt-4 btn-sm btn rounded-pill btn-primary">View Portfolio</a>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="col-lg-4">
            <div className="integration-list-wrap">
              <a href="integration-single.html" className="integration-7" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Your Brand Name">
                <img src="assets/img/integations/7.png" alt="integration" className="img-fluid rounded-circle" />
              </a>
              <a href="integration-single.html" className="integration-8" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Your Brand Name">
                <img src="assets/img/integations/8.png" alt="integration" className="img-fluid rounded-circle" />
              </a>
              <a href="integration-single.html" className="integration-9" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Your Brand Name">
                <img src="assets/img/integations/9.png" alt="integration" className="img-fluid rounded-circle" />
              </a>
              <a href="integration-single.html" className="integration-10" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Your Brand Name">
                <img src="assets/img/integations/dropbox-2.png" alt="integration" className="img-fluid rounded-circle" />
              </a>
              <a href="integration-single.html" className="integration-11" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Your Brand Name">
                <img src="assets/img/integations/11.png" alt="integration" className="img-fluid rounded-circle" />
              </a>
              <a href="integration-single.html" className="integration-12" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Your Brand Name">
                <img src="assets/img/integations/12.png" alt="integration" className="img-fluid rounded-circle" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

  )
}
