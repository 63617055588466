import React from 'react'

export default function RetailAndEcommerce() {
  return (
      <div classname="main-wrapper">
          <section className="customer-review-tab pt-75 pb-3 bg-gradient text-white  position-relative z-2">
              <div className="container">
                  <div className="row">
                      <div className="col-12">
                          <div className="tab-content" id="testimonial-tabContent">
                              <div className="tab-pane fade active show" id="testimonial-tab-1" role="tabpanel">
                                  <div className="row align-items-center justify-content-between">
                                      <div className="col-lg-7 col-md-6">
                                          <div className="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                                              <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width={65} className="img-fluid mb-32" />
                                              <div className="blockquote-title-review mb-4">
                                                  <h1 className="mb-0 h1 fw-semi-bold">Need a Creative and Premium Web design</h1>
                                              </div>
                                              <blockquote className="blockquote">
                                                  <p>Creating a powerful edTech experience with emerging technologies</p>
                                              </blockquote>
                                          </div>
                                      </div>
                                      <div className="col-lg-5 col-md-6">
                                          <div className="author-img-wrap pt-5 ps-5">
                                              <div className="position-relative">
                                                  <img src="assets/img/innovations8.png" className="img-fluid" alt="testimonial author" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          {/*feature promo section start*/}
          <section className="ptb-60">
              <div className="container">
                  <div className="row align-items-center">
                      <div className="col-lg-7">
                          <div className="about-right">
                              <h2 className="mb-4">Delivering digital transformation for progressive brands</h2>
                              <h5>Best Education Software Development Team to Uplift Education Processes Globally</h5>
                              <p>
                                  There are many variations of passages of Lorem Ipsum available, but
                                  the majority have suffered alteration in some form, by injected
                                  humour There are many variations of passages of Lorem Ipsum available, but
                                  the majority have suffered alteration in some form, by injected
                                  humourThere are many variations of passages of Lorem Ipsum available, but
                                  the majority have suffered alteration in some form, by injected
                                  humour
                              </p>
                              <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                                  <li className="py-1">
                                      <div className="d-flex about-icon-box align-items-center">
                                          <div className="me-3">
                                              <img src="assets/img/pie-chart.png" alt />
                                          </div>
                                          <div>
                                              <h5>Expert around the world</h5>
                                          </div>
                                      </div>
                                  </li>
                                  <li className="py-1">
                                      <div className="d-flex about-icon-box align-items-center">
                                          <div className="me-3">
                                              <img src="assets/img/team.png" alt />
                                          </div>
                                          <div>
                                              <h5>Best Practice For Business</h5>
                                          </div>
                                      </div>
                                  </li>
                                  <li className="py-1">
                                      <div className="d-flex about-icon-box align-items-center">
                                          <div className="me-3">
                                              <img src="assets/img/pie-chart.png" alt />
                                          </div>
                                          <div>
                                              <h5>Expert around the world</h5>
                                          </div>
                                      </div>
                                  </li>
                                  <li className="py-1">
                                      <div className="d-flex about-icon-box align-items-center">
                                          <div className="me-3">
                                              <img src="assets/img/team.png" alt />
                                          </div>
                                          <div>
                                              <h5>Best Practice For Business</h5>
                                          </div>
                                      </div>
                                  </li>
                                  <li className="py-1">
                                      <div className="d-flex about-icon-box align-items-center">
                                          <div className="me-3">
                                              <img src="assets/img/pie-chart.png" alt />
                                          </div>
                                          <div>
                                              <h5>Expert around For Business the world</h5>
                                          </div>
                                      </div>
                                  </li>
                                  <li className="py-1">
                                      <div className="d-flex about-icon-box align-items-center">
                                          <div className="me-3">
                                              <img src="assets/img/team.png" alt />
                                          </div>
                                          <div>
                                              <h5>Best Practice For Business For Business</h5>
                                          </div>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                      </div>
                      <div className="col-lg-5">
                          <div className="about-left text-lg-center mb-32 mb-lg-0">
                              <img src="assets/img/edtech14.png" alt="feature image" className="img-fluid rounded-custom" />
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="feature-section ptb-60">
              <div className="container">
                  <div className="row align-items-lg-center justify-content-between">
                      <div className="col-lg-6 order-lg-2 mb-7 mb-lg-0">
                          <div className="mb-4 aos-init aos-animate" data-aos="fade-up">
                              <h2>School&nbsp;Management&nbsp;System</h2>
                              <h4>An integrated Solutions for eLearning</h4>
                              <p>Enthusiastically administrate robust initiatives quickly unleash collaborative with client-focused paradigms. Re-engineer granular innovation rather than best-of-breed processes. </p>
                          </div>
                          <ul className="list-unstyled d-flex flex-wrap list-two-col mt-5 aos-init aos-animate" data-aos="fade-up">
                              <li>
                                  <h3 className="h5">Humans on hand</h3>
                                  <p>Objectively exceptional via customized via expertise.</p>
                              </li>
                              <li>
                                  <h3 className="h5">Strong privacy</h3>
                                  <p>Interactively integrate extensible users resource. </p>
                              </li>
                          </ul>
                      </div>
                      <div className="col-lg-6 order-lg-1">
                          <div className="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                              <div className="bg-light text-center rounded-custom overflow-hidden pt-5  mx-lg-auto">
                                  <div className="position-relative w-100 mx-auto">
                                      <img className="position-relative z-2 w-100 h-auto" src="assets/img/WhyEdu3.png" alt="Image Description" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="feature-section ptb-60">
              <div className="container">
                  <div className="row align-items-lg-center justify-content-between">
                      <div className="col-lg-6 order-lg-1 mb-7 mb-lg-0">
                          <div className="mb-4 aos-init aos-animate" data-aos="fade-up">
                              <h2>Student&nbsp;Information&nbsp;System</h2>
                              <h4>An integrated Solutions for eLearning</h4>
                              <p>Enthusiastically administrate robust initiatives quickly unleash collaborative with client-focused paradigms. Re-engineer granular innovation rather than best-of-breed processes. </p>
                          </div>
                          <ul className="list-unstyled d-flex flex-wrap list-two-col mt-5 aos-init aos-animate" data-aos="fade-up">
                              <li>
                                  <h3 className="h5">Humans on hand</h3>
                                  <p>Objectively exceptional via customized via expertise.</p>
                              </li>
                              <li>
                                  <h3 className="h5">Strong privacy</h3>
                                  <p>Interactively integrate extensible users resource. </p>
                              </li>
                          </ul>
                      </div>
                      <div className="col-lg-6 order-lg-2">
                          <div className="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                              <div className="text-center rounded-custom overflow-hidden pt-5  mx-lg-auto">
                                  <div className="position-relative w-100 mx-auto">
                                      <img className="position-relative z-2 w-100 h-auto" src="assets/img/pathshala.png" alt="Image Description" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="feature-section pt-60">
              <div className="container">
                  <div className="row align-items-lg-center justify-content-between">
                      <div className="col-lg-6 order-lg-2 mb-7 mb-lg-0">
                          <div className="mb-4 aos-init aos-animate" data-aos="fade-up">
                              <h2>Learning&nbsp;Management&nbsp;(LMS)</h2>
                              <h4>An integrated Solutions for eLearning</h4>
                              <p>Enthusiastically administrate robust initiatives quickly unleash collaborative with client-focused paradigms. Re-engineer granular innovation rather than best-of-breed processes. </p>
                          </div>
                          <ul className="list-unstyled d-flex flex-wrap list-two-col mt-5 aos-init aos-animate" data-aos="fade-up">
                              <li>
                                  <h3 className="h5">Live Class</h3>
                                  <p>Objectively exceptional via customized via expertise.</p>
                              </li>
                              <li>
                                  <h3 className="h5">Intractive Class</h3>
                                  <p>Interactively integrate extensible users resource. </p>
                              </li>
                              <li>
                                  <h3 className="h5">Online Learning</h3>
                                  <p>Interactively integrate extensible users resource. </p>
                              </li>
                              <li>
                                  <h3 className="h5">Online Test</h3>
                                  <p>Interactively integrate extensible users resource. </p>
                              </li>
                          </ul>
                      </div>
                      <div className="col-lg-6 order-lg-1">
                          <div className="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                              <div className=" text-center rounded-custom overflow-hidden pt-5  mx-lg-auto">
                                  <div className="position-relative w-100 mx-auto">
                                      <img className="position-relative z-2 w-100 h-auto" src="assets/img/EduLearn2.png" alt="Image Description" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="feature-section ptb-60">
              <div className="container">
                  <div className="row align-items-lg-center justify-content-between">
                      <div className="col-lg-6 order-lg-1 mb-7 mb-lg-0">
                          <div className="mb-4 aos-init aos-animate" data-aos="fade-up">
                              <h2>Online&nbsp;Examination&nbsp;System</h2>
                              <h4>Online Examination System Development</h4>
                              <p>Enthusiastically administrate robust initiatives quickly unleash collaborative with client-focused paradigms. Re-engineer granular innovation rather than best-of-breed processes. </p>
                          </div>
                          <ul className="list-unstyled d-flex flex-wrap list-two-col mt-5 aos-init aos-animate" data-aos="fade-up">
                              <li>
                                  <h3 className="h5">Humans on hand</h3>
                                  <p>Objectively exceptional via customized via expertise.</p>
                              </li>
                              <li>
                                  <h3 className="h5">Strong privacy</h3>
                                  <p>Interactively integrate extensible users resource. </p>
                              </li>
                          </ul>
                      </div>
                      <div className="col-lg-6 order-lg-2">
                          <div className="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                              <div className="text-center rounded-custom overflow-hidden pt-5  mx-lg-auto">
                                  <div className="position-relative w-100 mx-auto">
                                      <img className="position-relative z-2 w-100 h-auto" src="assets/img/online_exam6.jpg" alt="Image Description" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="integration-section  ptb-60">
              <div className="container">
                  <div className="row align-items-center justify-content-lg-between">
                      <div className="col-lg-10 col-md-12">
                          <div className="section-heading">
                              <h2>Embrace the latest tech trends in business technology and achieve digital acceleration</h2>
                          </div>
                      </div>
                      <div className="col-lg-2 col-md-12">
                          <div className="text-lg-end mb-5">
                              <a href="contacts.php" className="btn btn-sm btn-primary rounded-pill">Describe Your Project</a>
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-12">
                          <div className="integration-wrapper position-relative w-100">
                              <ul className="integration-list list-unstyled mb-0">
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/csharp.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">Dot Net Core</h6>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/dotnet.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">Dot Net</h6>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/java.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">Java</h6>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/react2.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">React</h6>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/angular.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">Angular</h6>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/mongo.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">Mongo DB</h6>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/oracledb.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">Oracle DB</h6>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/mssql.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">SQL Server</h6>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/wordpress.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">Wordpress</h6>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/jenkins.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">Jenkins</h6>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/figma.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">Brand Name</h6>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/drupal.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">Drupal</h6>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/html.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">HTML</h6>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/css.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">CSS</h6>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/phpmysql.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">Php MySQL</h6>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/nodejs.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">Node JS</h6>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/aws.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">Amazon Web Service</h6>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="single-integration">
                                          <img src="assets/img/icons/azure.png" alt="integration" className="img-fluid" />
                                          <h6 className="mb-0 mt-4">Azure</h6>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>

  )
}
