import Routers from './coomponents/routers/Routers';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <>
    <Routers/>
    </>
  );
}

export default App;
