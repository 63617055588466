import React from 'react'
import { NavLink } from 'react-router-dom'
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';

export default function Header() {
  return (
    <>
    {/*preloader start*/}
    {/* <div id="preloader">
        <div className="preloader-wrap">
            <img src="assets/img/favicon1.png" alt="logo" className="img-fluid preloader-icon" />
            <div className="loading-bar" />
        </div>
    </div> */}
    {/*preloader end*/}
    {/*main content wrapper start*/}
    <div className="main-wrapper">
        {/*header section start*/}
        {/*header start*/}
        <header className="main-header position-absolute w-100">
            <nav className="navbar navbar-expand-xl navbar-dark sticky-header z-10">
                <div className="container d-flex align-items-center justify-content-lg-between position-relative">
                    <NavLink to="/"  className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none">
                        <img style={{height:'60px'}} src="assets/img/logos/fornegologo.png" alt="logo" className="img-fluid logo-white" />
                        <img style={{height:'55px'}} src="assets/img/FornegoLogo.png" alt="logo" className="img-fluid logo-color" />
                    </NavLink>
                    <a className="navbar-toggler position-absolute right-0 border-0" href="#offcanvasWithBackdrop">
                        <span className="far fa-bars" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop" data-bs-toggle="offcanvas" role="button" />
                    </a>
                    <div className="clearfix" />
                    <div className="collapse navbar-collapse justify-content-center">
                        <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                            <li><NavLink to="/" className="nav-link">Home</NavLink></li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle nav-link_active" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Services
                                </a>
                                <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                                    <div className="dropdown-grid rounded-custom width-half">
                                        <div className="dropdown-grid-item">
                                            <h6 className="drop-heading">Services we offer</h6>
                                            <NavLink to="/webdevelopment" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">Web Development</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/ui-ux" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">UI/UX Designing</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/graphics-animation" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">Graphics and Animation</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/software-development" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">Software Development</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/mobile-application" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">Mobile App Development</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/cloud-devops" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">Cloud &amp; DevOps Solution</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/staffing-outsourcing" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">Staffing and Outsourceing</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/iot" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">Research&nbsp;&amp;&nbsp;Dev&nbsp;of&nbsp;IOT</div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Industries
                                </a>
                                <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                                    <div className="dropdown-grid rounded-custom width-half">
                                        <div className="dropdown-grid-item">
                                            <h6 className="drop-heading">Industries Expertise</h6>
                                            <NavLink to="/edtech" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">EdTech Industry</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/retail-ecommerce" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">Retail and Ecommerce</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="manufacturing" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">Manufacturing&nbsp;&amp;&nbsp;Automation</div>
                                                </div>
                                            </NavLink>
                                            <NavLink href="/hotel" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">Hotel&nbsp;&amp;&nbsp;Restaurants</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/realestate" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">Realstate Industry</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="healthcare" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">Healthcare Industry</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="travel" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">Travel Industry</div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Products
                                </a>
                                <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                                    <div className="dropdown-grid rounded-custom width-half">
                                        <div className="dropdown-grid-item">
                                            <h6 className="drop-heading">Our Products</h6>
                                            <NavLink to="/schoolmanagement" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">School Management</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/hotel-management" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">Hotel Management</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/pos" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">Restaurants POS</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/erp" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">ERP Software</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/billing" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">Inventory and Billing</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/hrmspayroll" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">HR and Payroll</div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li><NavLink to="/integrations" className="nav-link">Integrations</NavLink></li>
                            <li><NavLink to="/portfolio" className="nav-link">Portfolio</NavLink></li>
                            <li><NavLink to="/company" className="nav-link">Company</NavLink></li>
                            <li><NavLink to="/contactus" className="nav-link">ContactUs</NavLink></li>
                        </ul>
                    </div>
                    {/* <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
                        <a href="login.html" class="btn btn-link text-decoration-none me-2">Sign In</a>
                        <NavLink to="/request-demo" className="btn btn-sm btn-danger">Request for Demo</NavLink>
                    </div> */}
                </div>
            </nav>
            {/*offcanvas menu start*/}
            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasWithBackdrop">
                <div className="offcanvas-header d-flex align-items-center mt-4">
                    <a href="index.html" className="d-flex align-items-center mb-md-0 text-decoration-none">
                        <img src="assets/img/FornegoLogo.png" alt="logo" className="img-fluid ps-2" />
                    </a>
                    <button type="button" className="close-btn text-danger" data-bs-dismiss="offcanvas" aria-label="Close">
                        <i className="far fa-close" />
                    </button>
                </div>
                <div className="offcanvas-body z-10">
                    <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                        <li data-bs-dismiss="offcanvas" aria-label="Close"><NavLink to="/" className="nav-link" >Home</NavLink></li>
                        
                        <li className="nav-item dropdown">
                            <a  className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Services
                            </a>
                            <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                                <div className="dropdown-grid rounded-custom width-half">
                                    <div className="dropdown-grid-item">
                                    <h6 className="drop-heading">Services we offer</h6>
                                            <NavLink to="/webdevelopment" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">Web Development</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/ui-ux" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">UI/UX Designing</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/graphics-animation" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">Graphics and Animation</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/software-development" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">Software Development</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/mobile-application" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">Mobile App Development</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/cloud-devops" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">Cloud &amp; DevOps Solution</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/staffing-outsourcing" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">Staffing and Outsourceing</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/iot" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">Research&nbsp;&amp;&nbsp;Dev&nbsp;of&nbsp;IOT</div>
                                                </div>
                                            </NavLink>
                                    </div>
                                </div>
                            </div>
                        </li>
                      

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Industries</a>
                            <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                                <div className="dropdown-grid rounded-custom width-full-3">
                                    <div className="dropdown-grid-item">
                                    <h6 className="drop-heading">Industries Expertise</h6>
                                            <NavLink to="/edtech" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">EdTech Industry</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/retail-ecommerce" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">Retail and Ecommerce</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="manufacturing" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">Manufacturing&nbsp;&amp;&nbsp;Automation</div>
                                                </div>
                                            </NavLink>
                                            <NavLink href="/hotel" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">Hotel&nbsp;&amp;&nbsp;Restaurants</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/realstate" className="dropdown-link">
                                                <div className="dropdown-info">
                                                    <div className="drop-title">Realstate Industry</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="healthcare" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">Healthcare Industry</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="travel" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">Travel Industry</div>
                                                </div>
                                            </NavLink>
                                    </div>
                                    <div className="dropdown-grid-item last-item bg-light radius-right-side">
                                        <a href="#"><img src="assets/img/feature-img3.jpg" alt="add" className="img-fluid rounded-custom" /></a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Products</a>
                            <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                                <div className="dropdown-grid rounded-custom width-full-3">
                                    <div className="dropdown-grid-item">
                                    <h6 className="drop-heading">Our Products</h6>
                                            <NavLink to="/schoolmanagement" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">School Management</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/hotel-management" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">Hotel Management</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/pos" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">Restaurants POS</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/erp" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">ERP Software</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/billing" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">Inventory and Billing</div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/hrmspayroll" className="dropdown-link">
                                                <div data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-info">
                                                    <div className="drop-title">HR and Payroll</div>
                                                </div>
                                            </NavLink>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li data-bs-dismiss="offcanvas" aria-label="Close"><NavLink to="/integrations" className="nav-link">Integrations</NavLink></li>
                        <li data-bs-dismiss="offcanvas" aria-label="Close"><NavLink to="/portfolio" className="nav-link">Portfolio</NavLink></li>
                        <li data-bs-dismiss="offcanvas" aria-label="Close"><NavLink to="/company" className="nav-link">Company</NavLink></li>
                        <li data-bs-dismiss="offcanvas" aria-label="Close"><NavLink to="/contactus" className="nav-link">ContactUs</NavLink></li>
                    </ul>
                    <div className="action-btns mt-4 ps-3">
                        <a href="login.html" className="btn btn-outline-primary me-2">Sign In</a>
                        <a href="request-demo.html" className="btn btn-primary">Get Started</a>
                    </div>
                </div>
            </div>
            {/*offcanvas menu end*/}
        </header>
        {/*header end*/}
        {/*header section end*/}
    </div>
</>
  )
}
