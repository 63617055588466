import React from 'react'

export default function Integrations() {
    return (
        <div classNamename="main-wrapper">
            <section className="customer-review-tab pt-75 pb-3 bg-gradient text-white  position-relative z-2">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="tab-content" id="testimonial-tabContent">
                                <div className="tab-pane fade active show" id="testimonial-tab-1" role="tabpanel">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-lg-7 col-md-7 col-12">
                                            <div className="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                                                <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width={65} className="img-fluid mb-32" />
                                                <div className="blockquote-title-review mb-4">
                                                    <h1 className="mb-0 h1 fw-semi-bold">Request a product or service Demo</h1>
                                                </div>
                                                <blockquote className="blockquote">
                                                    <p>Request a demo today to see how our privacy management software can help your organization operationalize compliance and privacy by design.</p>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-md-6 col-12">
                                            <div className="author-img-wrap pt-5 ps-5">
                                                <div className="position-relative">
                                                    <img src="assets/img/innovations8.png" className="img-fluid" alt="testimonial author" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="feature-section pt-60">
                <div class="container">
                    <div class="row align-items-lg-center justify-content-between">
                        <div class="col-lg-6 order-lg-1 mb-7 mb-lg-0">
                            <div class="mb-4 aos-init aos-animate" data-aos="fade-up">
                                <h2>Biomatric & RFID Attendace</h2>
                                <h4>Automatic attendance System Implementation</h4>
                                <p>Enthusiastically administrate robust initiatives quickly unleash collaborative with client-focused paradigms. Re-engineer granular innovation rather than best-of-breed processes. </p>
                            </div>
                            <ul class="list-unstyled d-flex flex-wrap list-two-col mt-5 aos-init aos-animate" data-aos="fade-up">
                                <li><h3 class="h5">Humans on hand</h3><p>Objectively exceptional via customized via expertise.</p></li>
                                <li><h3 class="h5">Strong privacy</h3><p>Interactively integrate extensible users resource. </p></li>
                            </ul>
                        </div>
                        <div class="col-lg-6 order-lg-2">
                            <div class="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                                <div class="text-center overflow-hidden mx-lg-auto">
                                    <div class="position-relative w-100 mx-auto">
                                        <img class="position-relative z-2 w-100 h-auto" src="assets/img/attendance.png" alt="Image Description" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="feature-section pt-60">
                <div class="container">
                    <div class="row align-items-lg-center justify-content-between">
                        <div class="col-lg-6 order-lg-1">
                            <div class="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                                <div class="text-center rounded-custom overflow-hidden pt-5  mx-lg-auto">
                                    <div class="position-relative w-100 mx-auto">
                                        <img class="position-relative z-2 w-100 h-auto" src="assets/img/close-up-fingerprint-reader.jpg" alt="Image Description" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 order-lg-1 mb-7 mb-lg-0">
                            <div class="mb-4 aos-init aos-animate" data-aos="fade-up">
                                <h2>Biomatric Integrations</h2>
                                <h4>Online Examination System Development</h4>
                                <p>Enthusiastically administrate robust initiatives quickly unleash collaborative with client-focused paradigms. Re-engineer granular innovation rather than best-of-breed processes. </p>
                            </div>
                            <ul class="list-unstyled d-flex flex-wrap list-two-col mt-5 aos-init aos-animate" data-aos="fade-up">
                                <li><h3 class="h5">Humans on hand</h3><p>Objectively exceptional via customized via expertise.</p></li>
                                <li><h3 class="h5">Strong privacy</h3><p>Interactively integrate extensible users resource. </p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section class="feature-section pt-60">
                <div class="container">
                    <div class="row align-items-lg-center justify-content-between">
                        <div class="col-lg-6 order-lg-1 mb-7 mb-lg-0">
                            <div class="mb-4 aos-init aos-animate" data-aos="fade-up">
                                <h2>GPS Integrations</h2>
                                <h4>Online Examination System Development</h4>
                                <p>Enthusiastically administrate robust initiatives quickly unleash collaborative with client-focused paradigms. Re-engineer granular innovation rather than best-of-breed processes. </p>
                            </div>
                            <ul class="list-unstyled d-flex flex-wrap list-two-col mt-5 aos-init aos-animate" data-aos="fade-up">
                                <li><h3 class="h5">Humans on hand</h3><p>Objectively exceptional via customized via expertise.</p></li>
                                <li><h3 class="h5">Strong privacy</h3><p>Interactively integrate extensible users resource. </p></li>
                            </ul>
                        </div>
                        <div class="col-lg-6 order-lg-2">
                            <div class="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                                <div class="text-center rounded-custom overflow-hidden pt-5  mx-lg-auto">
                                    <div class="position-relative w-100 mx-auto">
                                        <img class="position-relative z-2 w-100 h-auto" src="assets/img/Real time tracking.png" alt="Image Description" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="feature-section ptb-60">
                <div class="container">
                    <div class="row align-items-lg-center justify-content-between">
                        <div class="col-lg-6 order-lg-1">
                            <div class="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                                <div class="text-center rounded-custom overflow-hidden pt-5  mx-lg-auto">
                                    <div class="position-relative w-100 mx-auto">
                                        <img class="position-relative z-2 w-100 h-auto" src="assets/img/qrpayment.jpg" alt="Image Description" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 order-lg-1 mb-7 mb-lg-0">
                            <div class="mb-4 aos-init aos-animate" data-aos="fade-up">
                                <h2>Online Payment Device</h2>
                                <h4>Online Examination System Development</h4>
                                <p>Enthusiastically administrate robust initiatives quickly unleash collaborative with client-focused paradigms. Re-engineer granular innovation rather than best-of-breed processes. </p>
                            </div>
                            <ul class="list-unstyled d-flex flex-wrap list-two-col mt-5 aos-init aos-animate" data-aos="fade-up">
                                <li><h3 class="h5">Humans on hand</h3><p>Objectively exceptional via customized via expertise.</p></li>
                                <li><h3 class="h5">Strong privacy</h3><p>Interactively integrate extensible users resource. </p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cyber-store-product">
                <div className="store-bg-header integrationshop">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 col-lg-10">
                                <div className="section-heading text-center mb-5">
                                    <h1 className="text-white">Our popular Cyber security products</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row product-row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="single-product position-relative mb-30">
                                <div className="product-img">
                                    <img src="assets/img/store/pro-1.jpg" alt="product photo" className="img-fluid" />
                                </div>
                                <div className="product-cart-wishlist-icon">
                                    <div className="cart">
                                        <i className="far fa-cart-shopping"></i>
                                    </div>
                                    <div className="heart">
                                        <i className="far fa-heart"></i>
                                    </div>
                                </div>
                                <div className="bg-white text-center shadow-sm py-4 product-info">
                                    <h6>
                                        <a href="product-single.html" className="text-decoration-none">Touch & Go Device</a>
                                    </h6>
                                    <span className="fw-bold">₹ 10000.00</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="single-product position-relative mb-30">
                                <div className="product-img">
                                    <img src="assets/img/store/pro-2.jpg" alt="product photo" className="img-fluid" />
                                </div>
                                <span className="bg-primary text-white px-2 rounded sale-badge">Sale!</span>
                                <div className="product-cart-wishlist-icon">
                                    <div className="cart">
                                        <i className="far fa-cart-shopping"></i>
                                    </div>
                                    <div className="heart">
                                        <i className="far fa-heart"></i>
                                    </div>
                                </div>
                                <div className="bg-white text-center shadow-sm py-4 product-info">
                                    <h6>
                                        <a href="product-single.html" className="text-decoration-none">Biometrics-Fingerprint
                                        </a>
                                    </h6>
                                    <span className="fw-bold">₹ 15000.00</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="single-product position-relative mb-30">
                                <div className="product-img">
                                    <img src="assets/img/store/pro-3.jpg" alt="product photo" className="img-fluid" />
                                </div>

                                <div className="product-cart-wishlist-icon">
                                    <div className="cart">
                                        <i className="far fa-cart-shopping"></i>
                                    </div>
                                    <div className="heart">
                                        <i className="far fa-heart"></i>
                                    </div>
                                </div>
                                <div className="bg-white text-center shadow-sm py-4 product-info">
                                    <h6>
                                        <a href="product-single.html" className="text-decoration-none">WeJupit Fingerprint Key</a>
                                    </h6>
                                    <span className="fw-bold">₹ 8000.00</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="single-product position-relative mb-30">
                                <div className="product-img">
                                    <img src="assets/img/store/pro-4.jpg" alt="product photo" className="img-fluid" />
                                </div>
                                <div className="product-cart-wishlist-icon">
                                    <div className="cart">
                                        <i className="far fa-cart-shopping"></i>
                                    </div>
                                    <div className="heart">
                                        <i className="far fa-heart"></i>
                                    </div>
                                </div>
                                <div className="bg-white text-center shadow-sm py-4 product-info">
                                    <h6>
                                        <a href="product-single.html" className="text-decoration-none">Smart Fingerprint Lock</a>
                                    </h6>
                                    <span className="fw-bold">₹ 7000.00</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*?php include("common/footer.php") ?*/}
        </div>

    )
}
