import React from 'react'

export default function Portfolio() {
  return (
      <div classname="main-wrapper">
          <section className="customer-review-tab pt-100 pb-3 bg-gradient text-white  position-relative z-2">
              <div className="container">
                  <div className="row">
                      <div className="col-12">
                          <div className="tab-content" id="testimonial-tabContent">
                              <div className="tab-pane fade active show" id="testimonial-tab-1" role="tabpanel">
                                  <div className="row align-items-center justify-content-between">
                                      <div className="col-lg-7 col-md-6">
                                          <div className="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                                              <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width={65} className="img-fluid mb-32" />
                                              <div className="blockquote-title-review mb-4">
                                                  <h1 className="mb-0 h1 fw-semi-bold">Our portfolio - what we have done so far</h1>
                                              </div>
                                              <blockquote className="blockquote">
                                                  <p>Designs that Reaped Great Benefits and Helped Young Entrepreneurs Turn into a Big Brand</p>
                                              </blockquote>
                                          </div>
                                      </div>
                                      <div className="col-lg-5 col-md-6">
                                          <div className="author-img-wrap pt-5 ps-5">
                                              <div className="testimonial-video-wrapper1 position-relative">
                                                  <img src="assets/img/innovations8.png" className="img-fluid rounded-custom shadow-lg" alt="testimonial author" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="cta-section bg-light  position-relative overflow-hidden mt-5 mt-lg-0">
              <div className="container">
                  <div className="row align-items-center justify-content-between">
                      <div className="col-lg-7 col-md-12">
                          <div className="position-relative z-5">
                              <h1>Explore our exceptional work Portfolio</h1>
                              <h5>MAQYUIE offers transformative technology and software solutions to startups,
                                  global brands &amp; Fortune companies.</h5>
                              <p>Conveniently syndicate client-centric users for transparent scenarios. Uniquely evolve
                                  partnerships rather than cross-platform interfaces.</p>
                              <p>Conveniently syndicate client-centric users for transparent scenarios. Uniquely evolve
                                  partnerships rather than cross-platform interfaces.</p>
                          </div>
                            <div class="row mb-5 mb-lg-0">
                                <div class="col-lg-4 col-6 col-sm-5"><a href="#" class="btn btn-danger rounded-pill mt-4">Request for Demo</a></div>
                                <div class="col-lg-6 col-6"><a href="#" target="_blank" class="btn btn-primary rounded-pill mt-4">Explore portfolio</a>
                            </div>
                          </div>
                      </div>
                      <div className="col-lg-5 col-md-12">
                          <div className="row align-items-center justify-content-center position-relative z-2">
                              <div className="col-md-6">
                                  <div className=" rounded-custom text-center shadow  bg-white my-4">
                                      <img src="assets/img/screen/audiance.png" />
                                  </div>
                                  <div className=" rounded-custom text-center shadow  bg-white my-4">
                                      <img src="assets/img/dg-shape-1.png" />
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className=" rounded-custom text-center shadow  bg-white my-4">
                                      <img src="assets/img/screen/widget-88.png" />
                                  </div>
                              </div>
                              <div className="bg-circle rounded-circle position-absolute z--1">
                                  <img src="assets/img/shape/blob.svg" alt="feature image" className="img-fluid rounded" />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="feature-section ptb-60">
              <div className="container">
                  <div className="row align-items-lg-center justify-content-between">
                      <div className="col-lg-6 order-lg-2 mb-7 mb-lg-0">
                          <div className="mb-4 aos-init aos-animate" data-aos="fade-up">
                              <h2>School Automation System</h2>
                              <h5>EDUMAQ - India's first fully automated ERP</h5>
                              <p className="text-justify">Running a school or a college is dealing with students in scale of thousands.
                                  An EDUMAQ ERP helps you organize the millions of records and keep track of all the
                                  necessary data. Our product is fully automated and simply making the entries at the
                                  right time offers you insights into all the relevant data. Our fully automated ERP
                                  offers great functionality at affordable rates. We believe the affordable rates help
                                  make education a bit more accessible to the country.
                              </p></div>
                          <div className="row">
                              <div className="col-lg-4 col-md-6 col-12 mb-3 mb-lg-0 ">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Customers</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h4>5000</h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-12  mb-3 mb-lg-0">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10 ">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Total&nbsp;Users</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h4>25000</h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-12 ">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Overall&nbsp;Rating</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <div className="blockquote-title-review mb-4">
                                                      <ul className="review-rate mb-0 list-unstyled list-inline">
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <ul className="list-unstyled d-flex flex-wrap list-three-col mt-2 aos-init aos-animate" data-aos="fade-up">
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> Poweful&nbsp;Academic</b>
                              </li>
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> Cloud Technology</b>
                              </li>
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> RealTime&nbsp;Tracking</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Integrated&nbsp;LMS</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Digital&nbsp;Payment</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> 100+ Module</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Mobile Application</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Powerful MIS</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> 200+ Reporting</b>
                              </li>
                          </ul>
                          <div className="row  mb-5 mb-lg-0">
                              <div className="col-lg-4 col-sm-5 col-6">
                                  <a href="#" className="btn btn-danger btn-sm rounded-pill mt-4">Request for Demo</a>
                              </div>
                              <div className="col-lg-6 col-7">
                                  <a href="https://www.edumaq.in/features.php" target="_blank" className="btn btn-primary btn-sm rounded-pill mt-4">Explore edumaq</a>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-6 order-lg-1">
                          <div className="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                              <div className="bg-light text-center rounded-custom overflow-hidden pt-5 px-3 px-lg-2 mx-lg-auto">
                                  <div className="position-relative w-100 mx-auto">
                                      <img className="position-relative mb-3 w-100 h-auto" src="assets/img/EdumaqCloudER2.png" alt="Image Description" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="feature-section ptb-60">
              <div className="container">
                  <div className="row align-items-lg-center justify-content-between">
                      <div className="col-lg-6 order-lg-1 mb-7 mb-lg-0">
                          <div className="mb-4 aos-init aos-animate" data-aos="fade-up">
                              <h2>Student Information System</h2>
                              <h5>EDUMAQ - India's first fully automated ERP</h5>
                              <p className="text-justify">
                                  PathShala is an online learning application. It provides a wide range of online
                                  courses in various categories like education, music, health &amp; fitness, etc...
                              </p></div>
                          <div className="row">
                              <div className="col-lg-4 col-md-6 col-12  mb-3 mb-lg-0 ">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Customers</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h4>5000</h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-12  mb-3 mb-lg-0 ">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Total&nbsp;Users</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h4>25000</h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-12">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Overall&nbsp;Rating</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <div className="blockquote-title-review mb-4">
                                                      <ul className="review-rate mb-0 list-unstyled list-inline">
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <ul className="list-unstyled d-flex flex-wrap list-three-col mt-2 aos-init aos-animate" data-aos="fade-up">
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> Poweful&nbsp;Academic</b>
                              </li>
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> Cloud Technology</b>
                              </li>
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> RealTime&nbsp;Tracking</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Integrated&nbsp;LMS</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Digital&nbsp;Payment</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> 100+ Module</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Mobile Application</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Powerful MIS</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> 200+ Reporting</b>
                              </li>
                          </ul>
                          <div className="row   mb-5 mb-lg-0">
                              <div className="col-lg-4 col-sm-5 col-6">
                                  <a href="#" className="btn btn-danger btn-sm rounded-pill mt-4">Request for Demo</a>
                              </div>
                              <div className="col-lg-6 col-7">
                                  <a href="https://www.edumaq.in/features.php" target="_blank" className="btn btn-primary btn-sm rounded-pill mt-4">Explore edumaq</a>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-6 order-lg-2">
                          <div className="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                              <div className="bg-light text-center rounded-custom overflow-hidden pt-5 px-3 px-lg-2 mx-lg-auto">
                                  <div className="position-relative w-100 mx-auto">
                                      <img className="position-relative mb-3 w-100 h-auto" src="assets/img/erp.png" alt="Image Description" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="feature-section ptb-60">
              <div className="container">
                  <div className="row align-items-lg-center justify-content-between">
                      <div className="col-lg-6 order-lg-2 mb-7 mb-lg-0">
                          <div className="mb-4 aos-init aos-animate" data-aos="fade-up">
                              <h2>Learning&nbsp;Management&nbsp;(LMS)</h2>
                              <h5>EDUMAQ - India's first fully automated ERP</h5>
                              <p className="text-justify">Running a school or a college is dealing with students in scale of thousands.
                                  An EDUMAQ ERP helps you organize the millions of records and keep track of all the
                                  necessary data. Our product is fully automated and simply making the entries at the
                                  right time offers you insights into all the relevant data. Our fully automated ERP
                                  offers great functionality at affordable rates. We believe the affordable rates help
                                  make education a bit more accessible to the country.
                              </p></div>
                          <div className="row">
                              <div className="col-lg-4 col-md-6 col-12  mb-3 mb-lg-0 ">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Customers</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h4>5000</h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-12  mb-3 mb-lg-0 ">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Total&nbsp;Users</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h4>25000</h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-12">
                                  <div className="row">
                                      <div className="col-lg-2 col-3">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Overall&nbsp;Rating</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <div className="blockquote-title-review mb-4">
                                                      <ul className="review-rate mb-0 list-unstyled list-inline">
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <ul className="list-unstyled d-flex flex-wrap list-three-col mt-2 aos-init aos-animate" data-aos="fade-up">
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> Poweful&nbsp;Academic</b>
                              </li>
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> Cloud Technology</b>
                              </li>
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> RealTime&nbsp;Tracking</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Integrated&nbsp;LMS</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Digital&nbsp;Payment</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> 100+ Module</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Mobile Application</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Powerful MIS</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> 200+ Reporting</b>
                              </li>
                          </ul>
                          <div className="row mb-5 mb-lg-0">
                              <div className="col-lg-4 col-sm-5 col-6">
                                  <a href="#" className="btn btn-danger btn-sm rounded-pill mt-4">Request for Demo</a>
                              </div>
                              <div className="col-lg-6 col-6">
                                  <a href="https://www.edumaq.in/features.php" target="_blank" className="btn btn-primary btn-sm rounded-pill mt-4">Explore edumaq</a>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-6 order-lg-1">
                          <div className="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                              <div className="bg-light text-center rounded-custom overflow-hidden pt-5 px-3 px-lg-2 mx-lg-auto">
                                  <div className="position-relative w-100 mx-auto">
                                      <img className="position-relative mb-3 w-100 h-auto" src="assets/img/EduConnect2.png" alt="Image Description" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="feature-section ptb-60">
              <div className="container">
                  <div className="row align-items-lg-center justify-content-between">
                      <div className="col-lg-6 order-lg-2 mb-7 mb-lg-0">
                          <div className="mb-4 aos-init aos-animate" data-aos="fade-up">
                              <h2>Live&nbsp;and&nbsp;Intractive&nbsp;Classes</h2>
                              <h5>EDUMAQ - India's first fully automated ERP</h5>
                              <p className="text-justify">Running a school or a college is dealing with students in scale of thousands.
                                  An EDUMAQ ERP helps you organize the millions of records and keep track of all the
                                  necessary data. Our product is fully automated and simply making the entries at the
                                  right time offers you insights into all the relevant data. Our fully automated ERP
                                  offers great functionality at affordable rates. We believe the affordable rates help
                                  make education a bit more accessible to the country.
                              </p></div>
                          <div className="row">
                              <div className="col-lg-4 col-md-6 col-12  mb-3 mb-lg-0">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Customers</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h4>5000</h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-12  mb-3 mb-lg-0">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Total&nbsp;Users</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h4>25000</h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-12">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Overall&nbsp;Rating</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <div className="blockquote-title-review mb-4">
                                                      <ul className="review-rate mb-0 list-unstyled list-inline">
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <ul className="list-unstyled d-flex flex-wrap list-three-col mt-2 aos-init aos-animate" data-aos="fade-up">
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> Poweful&nbsp;Academic</b>
                              </li>
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> Cloud Technology</b>
                              </li>
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> RealTime&nbsp;Tracking</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Integrated&nbsp;LMS</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Digital&nbsp;Payment</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> 100+ Module</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Mobile Application</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Powerful MIS</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> 200+ Reporting</b>
                              </li>
                          </ul>
                          <div className="row   mb-5 mb-lg-0">
                              <div className="col-lg-4 col-sm-5 col-6">
                                  <a href="#" className="btn btn-danger btn-sm rounded-pill mt-4">Request for Demo</a>
                              </div>
                              <div className="col-lg-6 col-7">
                                  <a href="https://www.edumaq.in/features.php" target="_blank" className="btn btn-primary btn-sm rounded-pill mt-4">Explore edumaq</a>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-6 order-lg-1">
                          <div className="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                              <div className="bg-light text-center rounded-custom overflow-hidden pt-5 px-3 px-lg-2 mx-lg-auto">
                                  <div className="position-relative w-100 mx-auto">
                                      <img className="position-relative mb-3 w-100 h-auto" src="assets/img/EduLearn2.png" alt="Image Description" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          {/*       */}
          <section className="feature-section ptb-60">
              <div className="container">
                  <div className="row align-items-lg-center justify-content-between">
                      <div className="col-lg-6 order-lg-1 mb-7 mb-lg-0">
                          <div className="mb-4 aos-init aos-animate" data-aos="fade-up">
                              <h2>MAQBill</h2>
                              <h5>A Cloud Based Billing &amp; Inventory Software</h5>
                              <p className="text-justify">Running a school or a college is dealing with students in scale of thousands.
                                  An EDUMAQ ERP helps you organize the millions of records and keep track of all the
                                  necessary data. Our product is fully automated and simply making the entries at the
                                  right time offers you insights into all the relevant data. Our fully automated ERP
                                  offers great functionality at affordable rates. We believe the affordable rates help
                                  make education a bit more accessible to the country.
                              </p></div>
                          <div className="row">
                              <div className="col-lg-4 col-md-6 col-12  mb-3 mb-lg-0">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Customers</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h4>5000</h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-12  mb-3 mb-lg-0">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Total&nbsp;Users</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h4>25000</h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-12">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Overall&nbsp;Rating</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <div className="blockquote-title-review mb-4">
                                                      <ul className="review-rate mb-0 list-unstyled list-inline">
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <ul className="list-unstyled d-flex flex-wrap list-three-col mt-2 aos-init aos-animate" data-aos="fade-up">
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> Poweful&nbsp;Academic</b>
                              </li>
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> Cloud Technology</b>
                              </li>
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> RealTime&nbsp;Tracking</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Integrated&nbsp;LMS</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Digital&nbsp;Payment</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> 100+ Module</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Mobile Application</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Powerful MIS</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> 200+ Reporting</b>
                              </li>
                          </ul>
                          <div className="row   mb-5 mb-lg-0">
                              <div className="col-lg-4 col-sm-5 col-6">
                                  <a href="#" className="btn btn-danger btn-sm rounded-pill mt-4">Request for Demo</a>
                              </div>
                              <div className="col-lg-6 col-7">
                                  <a href="https://www.edumaq.in/features.php" target="_blank" className="btn btn-primary btn-sm rounded-pill mt-4">Explore edumaq</a>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-6 order-lg-2">
                          <div className="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                              <div className="bg-light text-center rounded-custom overflow-hidden pt-5 px-3 px-lg-2 mx-lg-auto">
                                  <div className="position-relative w-100 mx-auto">
                                      <img className="position-relative mb-3 w-100 h-auto" src="assets/img/maqbill.png" alt="Image Description" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="feature-section ptb-60">
              <div className="container">
                  <div className="row align-items-lg-center justify-content-between">
                      <div className="col-lg-6 order-lg-2 mb-7 mb-lg-0">
                          <div className="mb-4 aos-init aos-animate" data-aos="fade-up">
                              <h2>Hospice</h2>
                              <h5>A Cloud Based Hotel Management Software</h5>
                              <p className="text-justify">Running a school or a college is dealing with students in scale of thousands.
                                  An EDUMAQ ERP helps you organize the millions of records and keep track of all the
                                  necessary data. Our product is fully automated and simply making the entries at the
                                  right time offers you insights into all the relevant data. Our fully automated ERP
                                  offers great functionality at affordable rates. We believe the affordable rates help
                                  make education a bit more accessible to the country.
                              </p></div>
                          <div className="row">
                              <div className="col-lg-4 col-md-6 col-12  mb-3 mb-lg-0">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Customers</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h4>5000</h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-12  mb-3 mb-lg-0">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Total&nbsp;Users</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h4>25000</h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-12">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Overall&nbsp;Rating</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <div className="blockquote-title-review mb-4">
                                                      <ul className="review-rate mb-0 list-unstyled list-inline">
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <ul className="list-unstyled d-flex flex-wrap list-three-col mt-2 aos-init aos-animate" data-aos="fade-up">
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> Poweful&nbsp;Academic</b>
                              </li>
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> Cloud Technology</b>
                              </li>
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> RealTime&nbsp;Tracking</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Integrated&nbsp;LMS</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Digital&nbsp;Payment</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> 100+ Module</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Mobile Application</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Powerful MIS</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> 200+ Reporting</b>
                              </li>
                          </ul>
                          <div className="row   mb-5 mb-lg-0">
                              <div className="col-lg-4 col-sm-5 col-6">
                                  <a href="#" className="btn btn-danger btn-sm rounded-pill mt-4">Request for Demo</a>
                              </div>
                              <div className="col-lg-6 col-7">
                                  <a href="https://www.edumaq.in/features.php" target="_blank" className="btn btn-primary btn-sm rounded-pill mt-4">Explore edumaq</a>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-6 order-lg-1">
                          <div className="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                              <div className="bg-light text-center rounded-custom overflow-hidden pt-5 px-3 px-lg-2 mx-lg-auto">
                                  <div className="position-relative w-100 mx-auto ">
                                      <img className="position-relative mb-3 w-100 h-auto" src="assets/img/hotelDashboard.png" alt="Image Description" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="feature-section ptb-60">
              <div className="container">
                  <div className="row align-items-lg-center justify-content-between">
                      <div className="col-lg-6 order-lg-1 mb-7 mb-lg-0">
                          <div className="mb-4 aos-init aos-animate" data-aos="fade-up">
                              <h2>Edumaq Dynamic Website</h2>
                              <h5>Dynamic Website for a EdTech Company</h5>
                              <p className="text-justify">Running a school or a college is dealing with students in scale of thousands.
                                  An EDUMAQ ERP helps you organize the millions of records and keep track of all the
                                  necessary data. Our product is fully automated and simply making the entries at the
                                  right time offers you insights into all the relevant data. Our fully automated ERP
                                  offers great functionality at affordable rates. We believe the affordable rates help
                                  make education a bit more accessible to the country.
                              </p></div>
                          <div className="row">
                              <div className="col-lg-4 col-md-6 col-12  mb-3 mb-lg-0">
                                  <div className="row">
                                      <div className="col-lg-3 col-2 ">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Customers</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h4>5000</h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-12  mb-3 mb-lg-0">
                                  <div className="row">
                                      <div className="col-lg-3 col-2">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Total&nbsp;Users</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h4>25000</h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-12">
                                  <div className="row">
                                      <div className="col-lg-3 col-2 ">
                                          <img height={45} src="assets/img/icons/shield.png" />
                                      </div>
                                      <div className="col-lg-9 col-10">
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <h6>Overall&nbsp;Rating</h6>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <div className="blockquote-title-review mb-4">
                                                      <ul className="review-rate mb-0 list-unstyled list-inline">
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                          <li className="list-inline-item"><i className="fas fa-star text-warning" />
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <ul className="list-unstyled d-flex flex-wrap list-three-col mt-2 aos-init aos-animate" data-aos="fade-up">
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> Poweful&nbsp;Academic</b>
                              </li>
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> Cloud Technology</b>
                              </li>
                              <li>
                                  <b><i className="fal fa-check-circle text-primary" /> RealTime&nbsp;Tracking</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Integrated&nbsp;LMS</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Digital&nbsp;Payment</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> 100+ Module</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Mobile Application</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> Powerful MIS</b>
                              </li>
                              <li className="mt-2">
                                  <b><i className="fal fa-check-circle text-primary" /> 200+ Reporting</b>
                              </li>
                          </ul>
                          <div className="row   mb-5 mb-lg-0">
                              <div className="col-lg-4 col-sm-5 col-6">
                                  <a href="#" className="btn btn-danger btn-sm rounded-pill mt-4">Request for Demo</a>
                              </div>
                              <div className="col-lg-6 col-7">
                                  <a href="https://www.edumaq.in/features.php" target="_blank" className="btn btn-primary btn-sm rounded-pill mt-4">Explore edumaq</a>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-6 order-lg-2">
                          <div className="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                              <div className="bg-light text-center rounded-custom overflow-hidden pt-5 px-3 px-lg-2 mx-lg-auto">
                                  <div className="position-relative w-100 mx-auto">
                                      <img className="position-relative mb-3 w-100 h-auto" src="assets/img/service/edumaq.png" alt="Image Description" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="why-choose-us ptb-60 bg-light">
              <div className="container">
                  <div className="row justify-content-lg-between align-items-center">
                      <div className="col-lg-6 col-12 order-1 order-lg-0">
                          <div className="feature-img-holder mt-5 mt-lg-0 mt-xl-0">
                              <img src="assets/img/screen/widget-7.png" className="img-fluid" alt="feature-image" />
                          </div>
                      </div>
                      <div className="col-lg-5 col-12">
                          <div className="why-choose-content">
                              <div className="icon-box rounded-custom bg-primary shadow-sm d-inline-block">
                                  <i className="fal fa-shield-check fa-2x text-white" />
                              </div>
                              <h2>Advanced Analytics, Understand Business</h2>
                              <p>Distinctively promote parallel vortals with ubiquitous e-markets. Proactively benchmark turnkey optimize next-generation strategic leadership without resource sucking ideas.</p>
                              <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                                  <li className="py-1"><i className="fas fa-check-circle me-2 text-primary" />Thought leadership</li>
                                  <li className="py-1"><i className="fas fa-check-circle me-2 text-primary" />Personal branding</li>
                                  <li className="py-1"><i className="fas fa-check-circle me-2 text-primary" />Modernized prospecting</li>
                                  <li className="py-1"><i className="fas fa-check-circle me-2 text-primary" />Better win rates</li>
                                  <li className="py-1"><i className="fas fa-check-circle me-2 text-primary" />Showcasing success</li>
                                  <li className="py-1"><i className="fas fa-check-circle me-2 text-primary" />Sales compliance</li>
                              </ul>
                              <a href="about-us.html" className="read-more-link text-decoration-none">Know More About Us <i className="far fa-arrow-right ms-2" /></a>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="home-blog-section ptb-60">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-lg-10 col-md-12">
                          <div className="section-heading text-center">
                              <h2>Most popular and top rated Products</h2>
                              <p>
                                  Assertively maximize cost effective methods of iterate team driven
                                  manufactured products through equity invested via customized
                                  benefits.
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="swiper cyber-blog swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                          <div className="swiper-wrapper" id="swiper-wrapper-378fd7737740c504" aria-live="off" style={{ transform: 'translate3d(-1910px, 0px, 0px)', transitionDuration: '0ms' }}><div className="swiper-slide position-relative swiper-slide-duplicate" data-swiper-slide-index={0} role="group" aria-label="1 / 3" style={{ width: 352, marginRight: 30 }}>
                              <div className="cyber-single-article mb-4 mb-lg-0 p-3 border">
                                  <a href="blog-single.html" className="cyber-article-img">
                                      <img src="assets/img/blog/c-blog-1.jpg" alt="article" className="img-fluid" />
                                  </a>
                                  <div className="article-content">
                                      <div className="article-info d-flex py-3">
                                          <div className="pe-3">
                                              <a href="#" className="text-decoration-none">
                                                  <i className="fas fa-user pe-2" />
                                                  <span className="text-secondary">Admin</span>
                                              </a>
                                          </div>
                                          <div>
                                              <a href="#" className="text-decoration-none">
                                                  <i className="fas fa-calendar pe-2" />
                                                  <span className="text-secondary">October 13, 2022</span>
                                              </a>
                                          </div>
                                      </div>
                                      <a href="blog-single.html" className="text-decoration-none">
                                          <h2 className="h5 article-title limit-2-line-text">
                                              The Steps to GainingPrivileged Access Security
                                          </h2>
                                      </a>
                                  </div>
                              </div>
                          </div><div className="swiper-slide position-relative swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index={1} role="group" aria-label="2 / 3" style={{ width: 352, marginRight: 30 }}>
                                  <div className="cyber-single-article mb-4 mb-lg-0 p-3 border">
                                      <a href="blog-single.html" className="cyber-article-img">
                                          <img src="assets/img/blog/c-blog-2.jpg" alt="article" className="img-fluid" />
                                      </a>
                                      <div className="article-content">
                                          <div className="article-info d-flex py-3">
                                              <div className="pe-3">
                                                  <a href="#" className="text-decoration-none">
                                                      <i className="fas fa-user pe-2" />
                                                      <span className="text-secondary">Admin</span>
                                                  </a>
                                              </div>
                                              <div>
                                                  <a href="#" className="text-decoration-none">
                                                      <i className="fas fa-calendar pe-2" />
                                                      <span className="text-secondary">October 13, 2022</span>
                                                  </a>
                                              </div>
                                          </div>
                                          <a href="blog-single.html" className="text-decoration-none">
                                              <h2 className="h5 article-title limit-2-line-text">
                                                  Protect Your Workplace FromCyber Attacks
                                              </h2>
                                          </a>
                                      </div>
                                  </div>
                              </div><div className="swiper-slide position-relative swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index={2} role="group" aria-label="3 / 3" style={{ width: 352, marginRight: 30 }}>
                                  <div className="cyber-single-article mb-4 mb-lg-0 p-3 border">
                                      <a href="blog-single.html" className="cyber-article-img">
                                          <img src="assets/img/blog/c-blog-3.jpg" alt="article" className="img-fluid" />
                                      </a>
                                      <div className="article-content">
                                          <div className="article-info d-flex py-3">
                                              <div className="pe-3">
                                                  <a href="#" className="text-decoration-none">
                                                      <i className="fas fa-user pe-2" />
                                                      <span className="text-secondary">Admin</span>
                                                  </a>
                                              </div>
                                              <div>
                                                  <a href="#" className="text-decoration-none">
                                                      <i className="fas fa-calendar pe-2" />
                                                      <span className="text-secondary">October 13, 2022</span>
                                                  </a>
                                              </div>
                                          </div>
                                          <a href="blog-single.html" className="text-decoration-none">
                                              <h2 className="h5 article-title limit-2-line-text">
                                                  The Steps to GainingPrivileged Access Security
                                              </h2>
                                          </a>
                                      </div>
                                  </div>
                              </div>
                              <div className="swiper-slide position-relative swiper-slide-duplicate-next" data-swiper-slide-index={0} role="group" aria-label="1 / 3" style={{ width: 352, marginRight: 30 }}>
                                  <div className="cyber-single-article mb-4 mb-lg-0 p-3 border">
                                      <a href="blog-single.html" className="cyber-article-img">
                                          <img src="assets/img/blog/c-blog-1.jpg" alt="article" className="img-fluid" />
                                      </a>
                                      <div className="article-content">
                                          <div className="article-info d-flex py-3">
                                              <div className="pe-3">
                                                  <a href="#" className="text-decoration-none">
                                                      <i className="fas fa-user pe-2" />
                                                      <span className="text-secondary">Admin</span>
                                                  </a>
                                              </div>
                                              <div>
                                                  <a href="#" className="text-decoration-none">
                                                      <i className="fas fa-calendar pe-2" />
                                                      <span className="text-secondary">October 13, 2022</span>
                                                  </a>
                                              </div>
                                          </div>
                                          <a href="blog-single.html" className="text-decoration-none">
                                              <h2 className="h5 article-title limit-2-line-text">
                                                  The Steps to GainingPrivileged Access Security
                                              </h2>
                                          </a>
                                      </div>
                                  </div>
                              </div>
                              <div className="swiper-slide position-relative swiper-slide-prev" data-swiper-slide-index={1} role="group" aria-label="2 / 3" style={{ width: 352, marginRight: 30 }}>
                                  <div className="cyber-single-article mb-4 mb-lg-0 p-3 border">
                                      <a href="blog-single.html" className="cyber-article-img">
                                          <img src="assets/img/blog/c-blog-2.jpg" alt="article" className="img-fluid" />
                                      </a>
                                      <div className="article-content">
                                          <div className="article-info d-flex py-3">
                                              <div className="pe-3">
                                                  <a href="#" className="text-decoration-none">
                                                      <i className="fas fa-user pe-2" />
                                                      <span className="text-secondary">Admin</span>
                                                  </a>
                                              </div>
                                              <div>
                                                  <a href="#" className="text-decoration-none">
                                                      <i className="fas fa-calendar pe-2" />
                                                      <span className="text-secondary">October 13, 2022</span>
                                                  </a>
                                              </div>
                                          </div>
                                          <a href="blog-single.html" className="text-decoration-none">
                                              <h2 className="h5 article-title limit-2-line-text">
                                                  Protect Your Workplace FromCyber Attacks
                                              </h2>
                                          </a>
                                      </div>
                                  </div>
                              </div>
                              <div className="swiper-slide position-relative swiper-slide-active" data-swiper-slide-index={2} role="group" aria-label="3 / 3" style={{ width: 352, marginRight: 30 }}>
                                  <div className="cyber-single-article mb-4 mb-lg-0 p-3 border">
                                      <a href="blog-single.html" className="cyber-article-img">
                                          <img src="assets/img/blog/c-blog-3.jpg" alt="article" className="img-fluid" />
                                      </a>
                                      <div className="article-content">
                                          <div className="article-info d-flex py-3">
                                              <div className="pe-3">
                                                  <a href="#" className="text-decoration-none">
                                                      <i className="fas fa-user pe-2" />
                                                      <span className="text-secondary">Admin</span>
                                                  </a>
                                              </div>
                                              <div>
                                                  <a href="#" className="text-decoration-none">
                                                      <i className="fas fa-calendar pe-2" />
                                                      <span className="text-secondary">October 13, 2022</span>
                                                  </a>
                                              </div>
                                          </div>
                                          <a href="blog-single.html" className="text-decoration-none">
                                              <h2 className="h5 article-title limit-2-line-text">
                                                  The Steps to GainingPrivileged Access Security
                                              </h2>
                                          </a>
                                      </div>
                                  </div>
                              </div>
                              <div className="swiper-slide position-relative swiper-slide-duplicate swiper-slide-next" data-swiper-slide-index={0} role="group" aria-label="1 / 3" style={{ width: 352, marginRight: 30 }}>
                                  <div className="cyber-single-article mb-4 mb-lg-0 p-3 border">
                                      <a href="blog-single.html" className="cyber-article-img">
                                          <img src="assets/img/blog/c-blog-1.jpg" alt="article" className="img-fluid" />
                                      </a>
                                      <div className="article-content">
                                          <div className="article-info d-flex py-3">
                                              <div className="pe-3">
                                                  <a href="#" className="text-decoration-none">
                                                      <i className="fas fa-user pe-2" />
                                                      <span className="text-secondary">Admin</span>
                                                  </a>
                                              </div>
                                              <div>
                                                  <a href="#" className="text-decoration-none">
                                                      <i className="fas fa-calendar pe-2" />
                                                      <span className="text-secondary">October 13, 2022</span>
                                                  </a>
                                              </div>
                                          </div>
                                          <a href="blog-single.html" className="text-decoration-none">
                                              <h2 className="h5 article-title limit-2-line-text">
                                                  The Steps to GainingPrivileged Access Security
                                              </h2>
                                          </a>
                                      </div>
                                  </div>
                              </div><div className="swiper-slide position-relative swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index={1} role="group" aria-label="2 / 3" style={{ width: 352, marginRight: 30 }}>
                                  <div className="cyber-single-article mb-4 mb-lg-0 p-3 border">
                                      <a href="blog-single.html" className="cyber-article-img">
                                          <img src="assets/img/blog/c-blog-2.jpg" alt="article" className="img-fluid" />
                                      </a>
                                      <div className="article-content">
                                          <div className="article-info d-flex py-3">
                                              <div className="pe-3">
                                                  <a href="#" className="text-decoration-none">
                                                      <i className="fas fa-user pe-2" />
                                                      <span className="text-secondary">Admin</span>
                                                  </a>
                                              </div>
                                              <div>
                                                  <a href="#" className="text-decoration-none">
                                                      <i className="fas fa-calendar pe-2" />
                                                      <span className="text-secondary">October 13, 2022</span>
                                                  </a>
                                              </div>
                                          </div>
                                          <a href="blog-single.html" className="text-decoration-none">
                                              <h2 className="h5 article-title limit-2-line-text">
                                                  Protect Your Workplace FromCyber Attacks
                                              </h2>
                                          </a>
                                      </div>
                                  </div>
                              </div><div className="swiper-slide position-relative swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index={2} role="group" aria-label="3 / 3" style={{ width: 352, marginRight: 30 }}>
                                  <div className="cyber-single-article mb-4 mb-lg-0 p-3 border">
                                      <a href="blog-single.html" className="cyber-article-img">
                                          <img src="assets/img/blog/c-blog-3.jpg" alt="article" className="img-fluid" />
                                      </a>
                                      <div className="article-content">
                                          <div className="article-info d-flex py-3">
                                              <div className="pe-3">
                                                  <a href="#" className="text-decoration-none">
                                                      <i className="fas fa-user pe-2" />
                                                      <span className="text-secondary">Admin</span>
                                                  </a>
                                              </div>
                                              <div>
                                                  <a href="#" className="text-decoration-none">
                                                      <i className="fas fa-calendar pe-2" />
                                                      <span className="text-secondary">October 13, 2022</span>
                                                  </a>
                                              </div>
                                          </div>
                                          <a href="blog-single.html" className="text-decoration-none">
                                              <h2 className="h5 article-title limit-2-line-text">
                                                  The Steps to GainingPrivileged Access Security
                                              </h2>
                                          </a>
                                      </div>
                                  </div>
                              </div></div>
                          <span className="swiper-notification" aria-live="assertive" aria-atomic="true" /></div>
                  </div>
              </div>
          </section>
      </div>

  )
}
