import React from 'react'
import { NavLink } from 'react-router-dom'
// import { Swiper } from 'swiper/react'
import Slider from "react-slick";



export default function Home() {
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        responsive: [
            {
              breakpoint: 776,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                dots: false
              }
            }
          ]
      };
    return (
        <>
            <div>
                {/* Hero Start */}
                <section className="hero-payment-gateway">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 mt-5 col-md-10">
                                <div className="payment-gateway-text">
                                    <h1 style={{marginTop:'165px'}} className="display-6 text-white mb-4 h1 fw-semi-bold">
                                        Let's&nbsp;change&nbsp;the&nbsp;world&nbsp;with
                                        <span className="gr-text fw-bold"> Technology ..</span>
                                    </h1>
                                    <p className="text-white m-0 pb-5">
                                        Every product is unique, but so is also our tailored process:
                                        we fuse strategy, creation and engineering.
                                        To genuinely innovate, we foster synergies beyond the established.
                                        Every product is unique, but so is also our tailored process:
                                    </p>
                                    <h5>
                                        <span className="gr-text">Let's build something amazing together !</span>
                                    </h5>
                                    <div className="action-btn mt-5 align-items-center d-block d-sm-flex d-lg-flex d-md-flex">
                                        <NavLink to="/request-demo" className="btn btn-sm btn-danger me-3">Request for Demo</NavLink>
                                        <NavLink to="/portfolio" className="btn btn-sm btn-danger me-3">View Portfolio</NavLink>
                                        {/* <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg" className="text-decoration-none popup-youtube d-inline-flex align-items-center watch-now-btn mt-3 mt-lg-0 mt-md-0">
                                            <i className="fas fa-play" /> Watch Demo
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 mt-3">
                                <div className="payment-hero-img pt-60 text-center text-lg-end position-relative">
                                    {/* <img src="assets/img/idea5.png" className="img-fluid ml-5" alt="smile" /> */}
                                    {/* <img src="assets/img/pay-hero-s.png" className="img-fluid h-shape" alt="smile" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="counter-with-video ptb-20" id="cyber-about">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-lg-5 col-md-12">
                        <div className="cyber-about-img text-center mb-30 mb-lg-0">
                            <img src="assets/img/innovation.jpg" alt="VR" className="img-fluid" />
                            <div className="row g-0">
                                <div className="col-lg-5">
                                    <div className="sheild-img">
                                        <img src="assets/img/about2.png" alt="Sheild" className="img-fluid d-none d-lg-block" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="pe-2">
                                        <div className="cyber-about-count-box d-md-flex bg-white p-4 mt-3">
                                            <div className="pe-3">
                                                <h2>15+</h2>
                                            </div>
                                            <div>
                                                <h5 className="h6">Years Experience</h5>
                                                <p className="mb-0">We Just Big Achived</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                            <div className="col-lg-12">
                                <div className="pt-0">
                                    <div className="section-heading mb-5">
                                        <h1 className>Fornego Innovations Lab</h1>
                                        <h4>Your digital strategy partner to craft awesome digital experience !</h4>
                                        <p style={{ textAlign: 'justify' }}>
                                            When it comes to a Software development company that’s reliable and produces timely
                                            results, then you must opt for MSPL. Our company provides a host of services
                                            like mobile and web apps that power brands and start-ups. We’ve worked on a host of
                                            projects and have a ton of experience which is beneficial for both parties.
                                            We are known for creating high-end mobile and web apps, that’ll surpass your
                                            expectations. As one of the leading app development companies, we have expertise in
                                            creating responsive and scalable mobile/ web apps. Our experience will help you not
                                            only in sustaining your business but also in growing it by manifolds.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="crypto-customer-counter ptb-0">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="crypto-customer-counter">
                                    <div className="counter-content section-title mb-30">
                                        <h2 className="mb-4">
                                            Our Best Users Are all over the <span className="gr-text">Worldwide</span> Coverage
                                        </h2>
                                        <p>
                                            You might wonder why a designer would choose to use Morem text
                                            Paragraphs Lorem Ipsum available but the majority{'{'}" "{'}'}
                                        </p>
                                        <p>
                                            embarrassing hidden in the middle of text. All the Lorem Ipsum
                                            generators on the Internet tend to repeat predefined chunks as
                                            necessary, making this the first true generator on the Internet.
                                        </p>
                                    </div>
                                    <div className="counter-box mb-5 mb-lg-0">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4">
                                                <div className="single-counter-box bg-dark mb-3 mb-md-0">
                                                    <h5 className="h4">25+</h5>
                                                    <span>Countries</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4">
                                                <div className="single-counter-box bg-dark mb-3 mb-md-0">
                                                    <h5 className="h4">10000+</h5>
                                                    <span>Customers</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4">
                                                <div className="single-counter-box bg-dark">
                                                    <h5 className="h4">15+</h5>
                                                    <span>Years Experience</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="maps-img text-lg-center">
                                    <img src="assets/img/world-map.png" className="img-fluid pl-20 pt-3" alt="Maps" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="services-icon pt-60 pb-0">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="text-center">
                                    <h2>We change what's possible, so you can build what's next</h2>
                                    <p>
                                        While understanding challenges that an organisation faces. We strive to equip our clients with scalable,
                                        efficient, and innovative computing services, software or apps to push the overall objective in the most
                                        efficient way possible.
                                    </p>
                                    <h3>We are experts in the following services</h3>
                                </div>
                                ,
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 p-0">
                                <div className="single-service p-lg-5 p-4 text-center mt-3 border-bottom border-end">
                                    <div className="service-icon icon-center">
                                        <img src="assets/img/icons/internet.gif" alt="service icon" width={110} height={110} />
                                    </div>
                                    <div className="service-info-wrap">
                                        <h3 className="h4">Web Development</h3>
                                        <p>
                                            Holisticly morph distinctive methodologies and vertical e-tailers.
                                            Rapidiously grow customized data.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 p-0">
                                <div className="single-service p-lg-5 p-4 text-center mt-3 border-bottom border-end">
                                    <div className="service-icon icon-center">
                                        <img src="assets/img/icons/browser.gif" alt="service icon" width={110} height={110} />
                                    </div>
                                    <div className="service-info-wrap">
                                        <h3 className="h4">Software Development</h3>
                                        <p>
                                            Holisticly morph distinctive methodologies and vertical e-tailers.
                                            Rapidiously grow customized data.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 p-0">
                                <div className="single-service p-lg-5 p-4 text-center mt-3 border-bottom">
                                    <div className="service-icon icon-center">
                                        <img src="assets/img/icons/face-scan.gif" alt="service icon" width={110} height={110} />
                                    </div>
                                    <div className="service-info-wrap">
                                        <h3 className="h4">App Development</h3>
                                        <p>
                                            Holisticly morph distinctive methodologies and vertical e-tailers.
                                            Rapidiously grow customized data.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 p-0">
                                <div className="single-service p-lg-5 p-4 text-center border-end">
                                    <div className="service-icon icon-center">
                                        <img src="assets/img/icons/cloud-network.gif" alt="service icon" width={110} height={110} />
                                    </div>
                                    <div className="feature-info-wrap">
                                        <h3 className="h4">Cloud and DevOps</h3>
                                        <p>
                                            Holisticly morph distinctive methodologies and vertical e-tailers.
                                            Rapidiously grow customized data.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 p-0">
                                <div className="single-service p-lg-5 p-4 text-center border-end">
                                    <div className="service-icon icon-center">
                                        <img src="assets/img/icons/around-the-world.gif" alt="service icon" width={110} height={110} />
                                    </div>
                                    <div className="feature-info-wrap">
                                        <h3 className="h4">Research &amp; Dev of IOT</h3>
                                        <p>
                                            Holisticly morph distinctive methodologies and vertical e-tailers.
                                            Rapidiously grow customized data.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 p-0">
                                <div className="single-service p-lg-5 p-4 text-center">
                                    <div className="service-icon icon-center">
                                        <img src="assets/img/icons/target.gif" alt="service icon" width={110} height={110} />
                                    </div>
                                    <div className="feature-info-wrap">
                                        <h3 className="h4">Digital Marketing</h3>
                                        <p>
                                            Holisticly morph distinctive methodologies and vertical e-tailers.
                                            Rapidiously grow customized data.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="cyber-features pt-60 pb-4 bg-light">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 col-lg-12">
                                <div className="section-heading text-center mb-5">
                                    <h2>Partnership &amp; Alliances</h2>
                                    <h6 className="gr-text">
                                        Partnerships and Strategic alliances with Industry Leaders to Help Our Clients Solve Critical Business Challenges
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="cyber-single-service bg-white rounded-custom mb-30">
                                    <img style={{ height: 100 }} src="assets/img/sap.png" />
                                    <div className="cyber feature-info-wrap mt-4">
                                        <h3 className="h5">Disaster Planning</h3>
                                        <p>
                                            Randomised words which don't look even passage of Lorem Ipsum.
                                            You need to be Lorem Ipsum randomised even .
                                        </p>
                                    </div>
                                    <a href="service-single.html" className="link-with-icon text-decoration-none">Explore More <i className="far fa-arrow-right" /></a>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="cyber-single-service bg-white rounded-custom mb-30">
                                    <img style={{ height: 100 }} src="assets/img/oci.png" />
                                    <div className="cyber feature-info-wrap">
                                        <h3 className="h5">Disaster Planning</h3>
                                        <p>
                                            Randomised words which don't look even passage of Lorem Ipsum.
                                            You need to be Lorem Ipsum randomised Lorem Ipsum randomised Lorem Ipsum randomised even .
                                        </p>
                                    </div>
                                    <a href="service-single.html" className="link-with-icon text-decoration-none">Explore More <i className="far fa-arrow-right" /></a>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="cyber-single-service bg-white rounded-custom mb-30">
                                    <img style={{ height: 100 }} src="assets/img/salesforce.png" />
                                    <div className="cyber feature-info-wrap mt-4">
                                        <h3 className="h5">Disaster Planning</h3>
                                        <p>
                                            Randomised words which don't look even passage of Lorem Ipsum.
                                            You need to be Lorem Ipsum randomised even .
                                        </p>
                                    </div>
                                    <a href="service-single.html" className="link-with-icon text-decoration-none">Explore More <i className="far fa-arrow-right" /></a>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="cyber-single-service bg-white rounded-custom mb-30">
                                    <img style={{ height: 100 }} src="assets/img/Twilio.png" />
                                    <div className="cyber feature-info-wrap mt-4">
                                        <h3 className="h5">Disaster Planning</h3>
                                        <p>
                                            Randomised words which don't look even passage of Lorem Ipsum.
                                            You need to be Lorem Ipsum randomised even .
                                        </p>
                                    </div>
                                    <a href="service-single.html" className="link-with-icon text-decoration-none">Explore More <i className="far fa-arrow-right" /></a>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="cyber-single-service bg-white rounded-custom mb-30">
                                    <img style={{ height: 100 }} src="assets/img/dynamics.png" />
                                    <div className="cyber feature-info-wrap">
                                        <h3 className="h5">Disaster Planning</h3>
                                        <p>
                                            Randomised words which don't look even passage of Lorem Ipsum.
                                            You need to be Lorem Lorem Ipsum randomised Lorem Ipsum randomised Ipsum randomised even .
                                        </p>
                                    </div>
                                    <a href="service-single.html" className="link-with-icon text-decoration-none">Explore More <i className="far fa-arrow-right" /></a>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="cyber-single-service bg-white rounded-custom mb-30">
                                    <img style={{ height: 100 }} src="assets/img/Tally.webp" />
                                    <div className="cyber feature-info-wrap mt-4">
                                        <h3 className="h5">Disaster Planning</h3>
                                        <p>
                                            Randomised words which don't look even passage of Lorem Ipsum.
                                            You need to be Lorem Ipsum randomised even .
                                        </p>
                                    </div>
                                    <a href="service-single.html" className="link-with-icon text-decoration-none">Explore More <i className="far fa-arrow-right" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="digi-why ptb-60">
                    <div className="container">
                        <div className="row align-content-center justify-content-between">
                            <div className="col-lg-5 col-md-12">
                                <div className="digi-why-left">
                                    <img src="assets/img/appointment-img.png" className="img-fluid pt-4" alt="Girl" />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="digi-why-right pt-4">
                                    <div>
                                        <span className="span-arrow">Why Choose Us</span>
                                        <img src="assets/img/arro-right.svg" className="img-fluid" alt="arrow" />
                                    </div>
                                    <h2>We create smart digital solutions for individuals startup companies and  brands</h2>
                                    <div className="mb-3">
                                        <h5 className="mb-2 h6">Transformative technology</h5>
                                        <p className="m-0">
                                            We constantly aspire to break boundaries and set new standards through the power of virtualization.
                                        </p>
                                    </div>
                                    <div className="mb-3">
                                        <h5 className="mb-2 h6">Excellence with purpose</h5>
                                        <p className="m-0">
                                            We don’t innovate for the sake of it - we put forth carefully crafted, well-designed products to answer real challenges in the real world.
                                        </p>
                                    </div>
                                    <div className="mb-3">
                                        <h5 className="mb-2 h6">Committed to our Clients</h5>
                                        <p className="m-0">
                                            We're dedicated to supporting our clients who seek to create truly frictionless, secure, and accessible applications.
                                        </p>
                                    </div>
                                    <div className=" mb-1">
                                        <h4>
                                            Your awesome project may start with a <span className="gr-text">Hi!</span>
                                        </h4>
                                    </div>
                                    <div>
                                        {/*  style={{ gridTemplateColumns: 'repeat(3, 1fr)' }} */}
                                        <ul className="list-unstyled mb-0 mt-5">
                                            <li className="d-flex p-2 align-items-center">
                                                <div className="me-3">
                                                    <img src="assets/img/icons/dw-i-1.svg" alt="icon" />
                                                </div>
                                                <div>
                                                    <h4 className="mb-0">10000+</h4>
                                                    <span>Happy&nbsp;Customer</span>
                                                </div>
                                            </li>
                                            <li className="pe-4 d-flex p-2 align-items-center">
                                                <div className="me-3">
                                                    <img src="assets/img/icons/dw-i-1.svg" alt="icon" />
                                                </div>
                                                <div>
                                                    <h4 className="mb-0">15000+</h4>
                                                    <span>Succesfull&nbsp;Project</span>
                                                </div>
                                            </li>
                                            <li className="pe-4 d-flex p-2 align-items-center">
                                                <div className="me-3">
                                                    <img src="assets/img/icons/dw-i-1.svg" alt="icon" />
                                                </div>
                                                <div>
                                                    <h4 className="mb-0">100+</h4>
                                                    <span>Employees</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Benifits Start */}
                <section className="benifits-area bg-white-light pt-60 pb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="benifits-left position-relative mb-5">
                                    <div>
                                        <img style={{ borderRadius: 5 }} src="assets/img/feature-img3.jpg" className="img-fluid text-center" alt="girl" />
                                    </div>
                                    <ul className="list-unstyled bi-right-shape">
                                        <li><img src="assets/img/ps-1.png" alt="shape" /></li>
                                        <li>
                                            <img src="assets/img/dg-shape-2.png" alt="shape" />
                                        </li>
                                        <li>
                                            <img src="assets/img/dg-shape-1.png" alt="shape" />
                                        </li>
                                        <li>
                                            <img src="assets/img/bi-pink-dot.png" alt="shape" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="benifits-right mt-5 mt-lg-0">
                                    <div className="benifits-right-content">
                                        <h2>Built high end IOT Projects using <span className="gr-text"><br />High end Technology</span></h2>
                                        <p className="m-0">
                                            This is where computer science takes shape and our roster of talent is positioned
                                            to understand both client and computing needs that leverage the overall experience.
                                            This is where computer science takes shape and our roster of talent is positioned
                                            to understand both client and computing needs that leverage the overall experience.
                                            This is where computer science takes shape and our roster of talent is positioned
                                            to understand both client and computing needs that leverage the overall experience.
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="integration-wrapper position-relative w-100">
                                                <ul className="integration-list list-unstyled mb-0">
                                                    <li>
                                                        <img style={{ width: 130 }} src="assets/img/azure.png" alt="Sheild" />
                                                    </li>
                                                    <li>
                                                        <img style={{ width: 160 }} src="assets/img/aws.png" alt="Sheild" />
                                                    </li>
                                                    <li>
                                                        <img style={{ width: 180 }} src="assets/img/iot/fornego.png" alt="Sheild" />
                                                    </li>
                                                    <li>
                                                        <img style={{ width: 150 }} src="assets/img/icons/satellitegps.png" alt="Sheild" />
                                                    </li>
                                                    <li>
                                                        <img style={{ width: 150 }} src="assets/img/icons/rfid.png" alt="Sheild" />
                                                    </li>
                                                    <li>
                                                        <img style={{ width: 150 }} src="assets/img/icons/gpsmap.png" alt="Sheild" />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Benifits End */}
                <section className="ptb-0">
                    <div className=" pt-60 pb-60">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-8">
                                    <div className="section-title mb-5 mb-lg-0">
                                        <h2 className="mb-3">
                                            We have worked accross multiple <span className="gr-text"> Industries</span>
                                        </h2>
                                        <p className="mb-2">
                                            Speed up building your app with a passionate Brancosoft experts helping you with not just
                                            the UI/UX, but also setting up your whole design, integration, publications in the stores
                                            and more
                                        </p>
                                    </div>
                                    <ul className="list-unstyled mt-5">
                                        <li className="d-flex align-items-start mb-4">
                                            <div className="icon-box bg-danger rounded me-4">
                                                <i className="fa fa-microchip text-white" />
                                            </div>
                                            <div className="icon-content">
                                                <h3 className="h4">Manufacturing &amp; Automation</h3>
                                                <p>Progressively foster enterprise-wide systems whereas equity invested
                                                    web-readiness harness installed.
                                                </p>
                                            </div>
                                        </li>
                                        <li className="d-flex align-items-start mb-4">
                                            <div className="icon-box bg-warning rounded me-4">
                                                <i className="fa fa-building text-white" />
                                            </div>
                                            <div className="icon-content">
                                                <h3 className="h4">Realstate Industry</h3>
                                                <p>Dramatically administrate progressive metrics without error-free globally
                                                    simplify standardized.
                                                </p>
                                            </div>
                                        </li>
                                        <li className="d-flex align-items-start mb-4">
                                            <div className="icon-box bg-primary rounded me-4">
                                                <i className="fas fa-bus text-white" />
                                            </div>
                                            <div className="icon-content">
                                                <h3 className="h4">Travel Industry</h3>
                                                <p>Dramatically administrate progressive metrics without error-free globally
                                                    simplify standardized.
                                                </p>
                                            </div>
                                        </li>
                                        <li className="d-flex align-items-start mb-4">
                                            <div className="icon-box bg-info rounded me-4">
                                                <i className="fa fa-inr text-white" />
                                            </div>
                                            <div className="icon-content">
                                                <h3 className="h4">Finance Industry</h3>
                                                <p>Dramatically administrate progressive metrics without error-free globally
                                                    simplify standardized.
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-5 col-md-12">
                                    <div className="game-service-right">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="row mb--200">
                                                    <div className="col-lg-12 col-md-6">
                                                        <div className="game-service-wrapper bg-dark mb-4">
                                                            <div style={{ height: 130, width: 130, borderRadius: '70px ! important' }} className="feature-icon icon-center rounded bg-danger mb-32">
                                                                <i className="fa fa-graduation-cap fa-5x text-white pt-4" />
                                                            </div>
                                                            <h6 className="text-white text-center fw-medium m-0">
                                                                EdTech&nbsp;Industry
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-6">
                                                        <div className="game-service-wrapper bg-dark mb-4">
                                                            <div style={{ height: 130, width: 130, borderRadius: '70px ! important' }} className="feature-icon icon-center rounded bg-info mb-32">
                                                                <i className="fa fa-cart-plus  fa-5x text-white pt-5" />
                                                            </div>
                                                            <h6 className="text-white text-center fw-medium m-0">
                                                                Retail&nbsp;&amp;&nbsp;Ecommerce
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="row mt--200">
                                                    <div className="col-lg-12 col-md-6">
                                                        <div className="game-service-wrapper bg-dark mb-4">
                                                            <div style={{ height: 130, width: 130, borderRadius: '70px ! important' }} className="feature-icon icon-center rounded bg-primary mb-32">
                                                                <i className="fa fa-user-md fa-5x text-white pt-4" />
                                                            </div>
                                                            <h6 className="text-white text-center fw-medium m-0">
                                                                Healthcare Industries
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-6">
                                                        <div className="game-service-wrapper bg-dark mb-4">
                                                            <div style={{ height: 130, width: 130, borderRadius: '70px ! important' }} className="feature-icon icon-center rounded bg-success mb-32">
                                                                <img style={{ height: 118, marginLeft: 10 }} src="assets/img/icons/cup.png" />
                                                            </div>
                                                            <h6 className="text-white text-center fw-medium m-0">
                                                                Hotel &amp; Restaurants
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="testimonial-section bg-white-light pt-50 pb-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="text-center mb-5">
                                    <h2 className="mb-3">
                                        Amezing Projects done by <span className="gr-text">maqyuie</span> Family
                                    </h2>
                                    <p className="m-0">
                                        This is where computer science takes shape and our roster of talent is positioned
                                        to understand both client and computing needs that leverage the overall experience.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="position-relative w-100 aos-init aos-animate mb-5" data-aos="fade-up" data-aos-delay={50}>
                                    
                                        <Slider {...settings}>
                                            <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index={1} role="group" aria-label="2 / 3" >
                                            <div className="pl-20 pr-20 rounded-custom position-relative">
                                                <img src="assets/img/testimonial/quotes-dot.svg" alt="quotes" width={100} className="img-fluid position-absolute left-0 top-0 z--1 p-3" />
                                                <div className="d-flex mb-32 align-items-center">
                                                    <img src="assets/img/tab-feature-img-5.png" className="img-fluid me-3 rounded" alt="user" />
                                                </div>
                                                <h5 className="text-center text-muted">HR and Payroll Management</h5>
                                            </div>
                                        </div>

                                        <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index={2} role="group" aria-label="3 / 3" >
                                                <div className="pl-20 pr-20 text-white rounded-custom position-relative">
                                                    <img src="assets/img/testimonial/quotes-dot.svg" alt="quotes" width={100} className="img-fluid position-absolute left-0 top-0 z--1 p-3" />
                                                    <div className="d-flex mb-32 align-items-center">
                                                        <img src="assets/img/tab-feature-img-4.png" className="img-fluid me-3 rounded" alt="user" />
                                                    </div>
                                                    <h5 className="text-center text-muted">Hotel Management System</h5>
                                                </div>
                                            </div>
                                            <div className="swiper-slide swiper-slide-duplicate-active" data-swiper-slide-index={0} role="group" aria-label="1 / 3" >
                                                <div className="pl-20 pr-20 text-white rounded-custom position-relative">
                                                    <img src="assets/img/testimonial/quotes-dot.svg" alt="quotes" width={100} className="img-fluid position-absolute left-0 top-0 z--1 p-3" />
                                                    <div className="d-flex mb-32 align-items-center">
                                                        <img src="assets/img/Design5.png" className="img-fluid me-3 rounded" alt="user" />
                                                    </div>
                                                    <h5 className="text-center text-muted">EDUMAQ School Management System</h5>
                                                </div>
                                            </div>
                                            <div className="swiper-slide swiper-slide-duplicate-next" data-swiper-slide-index={1} role="group" aria-label="2 / 3" >
                                                <div className="pl-20 pr-20 rounded-custom position-relative">
                                                    <img src="assets/img/testimonial/quotes-dot.svg" alt="quotes" width={100} className="img-fluid position-absolute left-0 top-0 z--1 p-3" />
                                                    <div className="d-flex mb-32 align-items-center">
                                                        <img src="assets/img/tab-feature-img-5.png" className="img-fluid me-3 rounded" alt="user" />
                                                    </div>
                                                    <h5 className="text-center text-muted">HR and Payroll Management</h5>
                                                </div>
                                            </div>
                                            <div className="swiper-slide swiper-slide-prev" data-swiper-slide-index={2} role="group" aria-label="3 / 3" >
                                                <div className="pl-20 pr-20 text-white rounded-custom position-relative">
                                                    <img src="assets/img/testimonial/quotes-dot.svg" alt="quotes" width={100} className="img-fluid position-absolute left-0 top-0 z--1 p-3" />
                                                    <div className="d-flex mb-32 align-items-center">
                                                        <img src="assets/img/tab-feature-img-4.png" className="img-fluid me-3 rounded" alt="user" />
                                                    </div>
                                                    <h5 className="text-center text-muted">Hotel Management System</h5>
                                                </div>
                                            </div>
                                            <div className="swiper-slide swiper-slide-duplicate swiper-slide-active" data-swiper-slide-index={0} role="group" aria-label="1 / 3" >
                                                <div className="pl-20 pr-20 text-white rounded-custom position-relative">
                                                    <img src="assets/img/testimonial/quotes-dot.svg" alt="quotes" width={100} className="img-fluid position-absolute left-0 top-0 z--1 p-3" />
                                                    <div className="d-flex mb-32 align-items-center">
                                                        <img src="assets/img/Design5.png" className="img-fluid me-3 rounded" alt="user" />
                                                    </div>
                                                    <h5 className="text-center text-muted">EDUMAQ School Management System</h5>
                                                </div>
                                            </div>
                                            <div className="swiper-slide swiper-slide-duplicate swiper-slide-next" data-swiper-slide-index={1} role="group" aria-label="2 / 3" >
                                                <div className="pl-20 pr-20 rounded-custom position-relative">
                                                    <img src="assets/img/testimonial/quotes-dot.svg" alt="quotes" width={100} className="img-fluid position-absolute left-0 top-0 z--1 p-3" />
                                                    <div className="d-flex mb-32 align-items-center">
                                                        <img src="assets/img/tab-feature-img-5.png" className="img-fluid me-3 rounded" alt="user" />
                                                    </div>
                                                    <h5 className="text-center text-muted">HR and Payroll Management</h5>
                                                </div>
                                            </div>
                                            </Slider>
                                            
                                        <span className="swiper-notification" aria-live="assertive" aria-atomic="true" /><span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
                                        </div>
                                </div>
                            </div>
                        </div>
                </section>

                <section className="contact-us ptb-60 position-relative overflow-hidden">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-7 col-lg-7 col-md-12">
                                <div className="section-heading aos-init aos-animate" data-aos="fade-up">
                                    <h2>Get in Touch Today !</h2>
                                    <p>We love to hear from you.Fill out the form below with some info about your project and
                                        I will get back to you as soon as I can. Please allow a few hours for me to respond. </p>
                                </div>
                                <h5 className="gr-text">MAQYUIE SOLUTIONS PVT LTD</h5>
                                <p>
                                    302 Sardha Second Floor, AECS LAYOUT 'A' Block.
                                    Brookefield<br /> Bengaluru Karnataka-560037 (INDIA)
                                </p><br />
                                <div className="row justify-content-between pb-5">
                                    <div className="col-sm-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay={100}>
                                        <div className="contact-info">
                                            <h5>Sales</h5>
                                            <b><i className="fa fa-map-marker" /> Bengaluru (India)</b><br />
                                            <b><i className="fa fa-envelope" /> sales@fornego.com</b><br />
                                            <b><i className="fa fa-phone" /> 09031010725</b>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay={100}>
                                        <div className="contact-info">
                                            <h5>Support</h5>
                                            <b><i className="fa fa-map-marker" /> Bengaluru (India)</b><br />
                                            <b><i className="fa fa-envelope" /> support@fornego.com</b><br />
                                            <b><i className="fa fa-phone" /> 09031010726</b>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay={100}>
                                        <div className="contact-info">
                                            <h5>HR Department</h5>
                                            <b><i className="fa fa-map-marker" /> Bengaluru (India)</b><br />
                                            <b><i className="fa fa-envelope" /> hr@fornego.com</b><br />
                                            <b><i className="fa fa-phone" /> 08095500010</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-7 col-md-12">
                                <div className="register-wrap p-4 bg-white shadow rounded-custom position-relative aos-init aos-animate" data-aos="fade-up" data-aos-delay={150}>
                                    <form action="#" className="register-form position-relative z-5">
                                        <h4 className="mb-5 fw-medium">Fill out the form and we'll be in touch as soon as
                                            possible.</h4>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control" placeholder="Full Name" aria-label="fullname" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 ">
                                                <div className="input-group mb-3">
                                                    <input type="email" className="form-control" placeholder="City" aria-label="city" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control" placeholder="Mobile Number" aria-label="mobileno" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="input-group mb-3">
                                                    <input type="email" className="form-control" placeholder="Work email" aria-label="work-Email" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="input-group mb-3">
                                                    <textarea className="form-control" placeholder="Tell us more about your project, needs and budget" style={{ height: 120 }} defaultValue={""} />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckChecked" />
                                                    <label className="form-check-label small" htmlFor="flexCheckChecked">
                                                        Yes, I'd like to receive occasional marketing emails from us. I have the
                                                        right to opt out at any time.
                                                        <a href="#"> View privacy policy</a>.
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button type="submit" className="btn btn-primary mt-4 d-block w-100">Get Started
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>

        </>
    )
}
