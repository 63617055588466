import React from 'react'

export default function Privacy() {
    return (
        <div classNamename="main-wrapper">
            <section className="customer-review-tab pt-75 pb-3 bg-gradient text-white  position-relative z-2">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="tab-content" id="testimonial-tabContent">
                                <div className="tab-pane fade active show" id="testimonial-tab-1" role="tabpanel">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-lg-7 col-md-7 col-12">
                                            <div className="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                                                <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width={65} className="img-fluid mb-32" />
                                                <div className="blockquote-title-review mb-4">
                                                    <h1 className="mb-0 h1 fw-semi-bold">Privacy Policy</h1>
                                                </div>
                                                <blockquote className="blockquote">
                                                    <p>Your privacy is important to us, and so is being transparent about how we collect, use, and share information about you. This policy is intended to help you understand:</p>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-md-6 col-12">
                                            <div className="author-img-wrap pt-5 ps-5">
                                                <div className="position-relative">
                                                    <img src="assets/img/innovations8.png" className="img-fluid" alt="testimonial author" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="feature-section pt-4">
                <div class="container">
                    <div class="row align-items-lg-center justify-content-between">
                        <h5>LAST UPDATED: December 05, 2023</h5>
                        <p className='text-justify'><b>Fornego Innovations Lab</b> respects and values your privacy. This Privacy Policy explains how we collect your Personal Data (defined below) on those web sites of Fornego Innovations Lab that post or link directly to this Privacy Policy (collectively, the “Site”) and through offline means, how we use, disclose, and protect such data, and the choices you have concerning our use of such data. Please read this Privacy Policy carefully. Fornego Innovations Lab may from time to time update this Privacy Policy. Any changes to this Privacy Policy will become effective when we post or link to the revised Privacy Policy on the Site. Any significant changes to the way we collect or use Personal Data will be highlighted on the homepage of the Site in advance. Please review the “Last Updated” legend at the top of this page to determine when this Privacy Policy was most recently revised.</p>
                        <p className='text-justify'>For the purposes of this Privacy Policy, “Fornego”, "we", "us", and "our" means Fornego Innovations Lab and its affiliates listed at www.fornego.com/privacy.</p>
                        <p className='text-justify'>In some circumstances, you may have the option to decline to provide us with any Personal Data to visit the Site. However, if you choose to withhold requested information, you may not be able to visit all sections or use all features of the Site.</p>
                        <p className='text-justify'>This Privacy Policy shall also apply to any Fornego mobile application and other forms of Fornego online activity that reference this Privacy Policy. In such instances, the term “Site” shall include the applicable mobile application or other Fornego online activity whenever that term is used herein.</p>
                        <h5>Collection of Personal Data</h5>
                        <p className='text-justify'>Personal data is information that identifies you or can be used to identify or contact you, e.g., your name, email address, address, or phone number ("Personal Data"). We may need to collect and process Personal Data in order to provide requested information, products or services to you (including the Site) or because we are legally required to do so.</p>
                        <p className='text-justify'>Fornego requests Personal Data from you if the Site requires registration. The registration process requests that you provide Personal Data, such as your name, business email address, business address or country/jurisdiction location, business phone number, and company affiliation, and agree to the User Agreement presented at the time of registration.</p>
                        <p className='text-justify'>Fornego may receive Personal Data from social media platforms. For example, if you elect to use your social media credentials to log-in to your Site account, certain Personal Data from your social media account will be shared with us, which may include Personal Data that is part of your social media profile.</p>
                        <p className='text-justify'>Fornego may collect Personal Data voluntarily provided by you, including in emails, online forms, order and registration forms, surveys, promotion participation, online chats, phone calls, and through other means</p>
                        <p className='text-justify'>On some of Fornego Sites, including www.fornego.com, you are able to maintain "portfolios," where, if you are a registered user, you have the option to list financial instruments in which you have a special interest. To be useful, this information must be stored on the Site, and is accessible to anyone with whom you share your username and password. For this reason, please safeguard your username and password carefully and do not disclose them to any other person.</p>
                        <p className='text-justify'>We may use third-party payment services (each, a "Payment Service") to collect payments made through the Site. If you wish to make a payment through the Site, you may be directed to a webpage that is hosted by a Payment Service (or a Payment Service's service providers) and not by Fornego. Any Personal Data that you provide through a Payment Service page will be collected by the Payment Service and not by Fornego, and will be subject to Payment Service's privacy policy, rather than this Privacy Policy. Fornego has no control over, and is not responsible for, a Payment Service's use of information collected through a Payment Service page.</p>
                        <p className='text-justify'>Our EdTech products and services contain limited Personal Data (name, address and telephone number) about School and College owners, Students, Parents, Teachesrs, buyers and sellers, who may at times be individuals and not corporate entities. We source this Personal Data directly and indirectly (via information providers) from public records, including county-level tax data and Secretary of State LLC, publicly- available websites, and crowdsourcing.</p>
                        <p className='text-justify'> Our NewsEdge product may contain limited Personal Data about executives, officers, managers, investors, and individual shareholders of organizations mentioned in news stories. We source this Personal Data directly and indirectly (via information providers) from public records (such as SEC filings), corporate disclosures and press releases, publicly available websites, newsfeeds, newspapers and magazines.
                        For information regarding our processing of Personal Data in connection with our other products, please see our separate GRID Privacy Notice, Orbis Privacy Notice, The Nordic PEP List Privacy Notice, and ESG Assessments Privacy Notice.</p>
                        <p className='text-justify'>Fornego collects information through various other means, including searches online, participation at conferences and events, individual introductions, from employers that enroll their employees in our services or provide services to us, and from selected third-party business partners. From time to time, we may also acquire from third parties certain lists containing the names and contact information of individuals who may be interested in our products and services.</p>
                        <h5>Use of Personal Data Collected</h5>
                        <p className='text-justify'>We use Personal Data transparently and in compliance with applicable law, for legitimate business purposes, including the following :</p>
                        <p className='text-justify'>Fulfillment of Requests. Fornego may use Personal Data about you to deliver products and services to you, and to provide you with information about products and services you’ve ordered. We will engage in these activities to manage our contractual relationship with you and to fulfil our contractual obligations.</p>
                        <p className='text-justify'>Submission on Fornego Analytics Pulse. We store and may publicly display your submissions in order to provide the services that we offer. When we display your submissions, we do so together with the user identification code (the “User ID”) you have established. If we display your submission, we may also contact you at the request of other users who wish to discuss your submission with you. We will not reveal your contact information to other users. The term “submissions” refers to the information that you submit or post to the Fornego Analytics Pulse site for public display, such as business ratings and reviews. There may be exceptions where your name and email address domain is shared with other members of the Fornego Analytics Pulse site when you elect to participate in discussions with other members. For example, within the Circles offering, if you create or reply to a discussion thread, your first and last name will be displayed along with your submission to the members of your Circle. Another example is, if you apply to join a Circle, your name and email address domain will be displayed to the manager of that Circle so that the manager can evaluate your application to join the Circle.</p>
                        <p className='text-justify'>Marketing Communications. From time to time, in compliance with applicable laws, we may use Personal Data about you to inform you of products, programs, services and promotions that we believe may be of interest to you. If your employer is a Fornego customer, we may also inform you of such products, programs, services and promotions using Personal Data about you that your employer provided to Fornego. We may also use Personal Data of media contacts for press release distribution or sharing other newsworthy developments related to our business activities. If you would prefer that we not send electronic marketing messages to you, please see the "Your Choices" section below.</p>
                        <p className='text-justify'>Promotions. We may operate sweepstakes, contests and similar promotions (collectively, "Promotions"). If you choose to enter Fornego Promotions, we may require certain Personal Data when you enter and, if applicable, win a Promotion. You should carefully review the rules of each Promotion in which you participate, as they may contain additional important information about our use of Personal Data about you. To the extent that the terms and conditions of such rules concerning the treatment of your Personal Data conflict with this Privacy Policy, the terms and conditions of such rules shall control. We will use this information to operate the Promotions.</p>
                        <p className='text-justify'>Other Business Purposes. We may also use Personal Data about you for other business purposes, such as data analysis (for example, to improve the efficiency of our services), editorial and feedback purposes, providing customer support, customizing and improving the content and layout of the Site, completing business transactions (for example, to confirm content requested is received by customers), development and delivery of marketing and promotion plans and materials, statistical analysis of user behavior (for example, to understand what parts of the Site are of most interest to users), product development, receipt of services from you or your employer, conducting surveys and market research, and administering individual accounts.</p>
                        <p className='text-justify'>Administrative Communications. Fornego reserves the right to use Personal Data to send to you important information regarding the Site, products, services, your account status, the User Agreement, this Privacy Policy, or any other policies, agreements or transactions relevant to your use of the Site, products and/or services. Because this information may be important to your use of the Site, products and/or services, you may not opt-out of receiving such communications. We will engage in this activity to manage our contractual relationship with you and/or to comply with a legal obligation.</p>
                        <p className='text-justify'> Compliance with law and regulation. We may use Personal Data as necessary to comply with applicable legal and regulatory requirements such as sales tax collection, audits, etc.</p>
                        <p className='text-justify'>Fornego uses Personal Data for purposes compatible with those notified in this Privacy Policy and any other online or offline applicable privacy notice, and in accordance with applicable law. Where required by applicable law, we will only use Personal Data with your consent.</p>
                        <h5>Cookies and Other Tracking Technologies</h5>
                        <p className='text-justify'>We use cookies and similar technologies (collectively, “Cookies”) on our websites</p>
                        <h5>How you can access, change or suppress your Personal Data</h5>
                        <p className='text-justify'>If the right is provided to you by applicable law and you would like to request to review, correct, update, suppress, delete or otherwise limit our use of your Personal Data that has been previously provided to us, or if you would like to request to receive an electronic copy of your Personal Data for purposes of transmitting it to another company, you may make a request by contacting us using the information provided in the “Contact Information for Fornego” section below. We will respond to your request consistent with applicable law. For your protection, we may only implement requests with respect to the Personal Data associated with the particular email address that you use to send us your request, and we may need to verify your identity before implementing your request. We will try to comply with your request as soon as reasonably practicable and consistent with applicable law. Please note that we may need to retain certain information for recordkeeping purposes and/or to complete any transactions that you began prior to requesting the change or deletion.</p>
                        <h5>Links</h5>
                        <p className='text-justify'>The Site may contain links to other Internet web sites, including social media sites and third-party hosted collaboration tools. These linked sites are not under Fornego control. We provide links only as a convenience, and Fornego does not endorse or control, and is not responsible for, the privacy practices or the content of these linked sites. If you provide any Personal Data through any third-party web site, or choose to communicate with us using third-party collaboration tools or other social media platforms, your transaction will occur on that third party's web site (not the Site) and the Personal Data you provide will be collected by, and controlled by the privacy policy of, that third party. We recommend that you familiarize yourself with the privacy policies and practices of any such third parties. PLEASE NOTE THAT THIS PRIVACY POLICY DOES NOT ADDRESS THE PRIVACY OR INFORMATION PRACTICES OF ANY THIRD PARTIES, INCLUDING, WITHOUT LIMITATION, AFFILIATED ENTITIES THAT DO NOT POST OR LINK DIRECTLY TO THIS PRIVACY POLICY.</p>
                        <h5>Security Measures</h5>
                        <p className='text-justify'>Fornego has established appropriate organizational and technical measures, including external third-party audits for particular products and services, to protect Personal Data within our organization from loss, misuse, or unauthorized access, disclosure, alteration or destruction. Fornego has processes and procedures for suspected privacy breaches to notify regulators and affected individuals where required in accordance with applicable law. Fornego requires applicable vendors that process Personal Data on its behalf to complete privacy and security assessments and be bound by contractual terms.</p>
                        <h5>Data Integrity</h5>
                        <p className='text-justify'>Fornego will use Personal Data only in ways that are compatible with the purposes for which it was collected, authorized by this Privacy Policy, or authorized by you. Fornego will take reasonable steps to ensure that Personal Data is relevant to its intended use, and is accurate, complete, and current (as provided by you). Fornego depends on you to update or correct your Personal Data whenever necessary</p>
                        <h5>Retention Period</h5>
                        <p className='text-justify'>We will retain Personal Data about you for as long as needed or permitted in light of the purpose(s) for which it was obtained and consistent with applicable law. The criteria used to determine our retention periods include: (i) the length of time we have an ongoing relationship with you; (ii) whether there is a legal obligation to which we are subject; and (iii) whether retention is advisable in light of our legal position (such as in regard to applicable statutes of limitations, litigation or regulatory investigations).</p>
                        <h5>Sensitive Personal Data Restriction</h5> 
                        <p className='text-justify'>Unless we specifically request for information to comply with applicable anti-money laundering laws and other applicable laws, we ask that you not send us, and you not disclose, any sensitive Personal Data (e.g., social security numbers, passport numbers, information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, criminal background or trade union membership) on or through this Site or otherwise to us.</p>
                        <h4>Contact Information for Fornego Innovations Lab</h4>
                        <h5> 302 Sardha Second Floor, AECS LAYOUT<br/>
                            A Block. Brookefield, Bengaluru<br/>
                            Karnataka , Pin Code - 560037 (INDIA)<br/><br/>
                            Email : Fornegolabs@gmail.com<br/>
                            Mobile No : 09031010725<br/><br/>
                        </h5>
                    </div>
                </div>
            </section>
            {/*?php include("common/footer.php") ?*/}
        </div>

    )
}

