import React from 'react'

export default function StaffingAndOutsourcing() {
  return (
    <div classname="main-wrapper">
      <section className="customer-review-tab pt-75 pb-3 bg-gradient text-white  position-relative z-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tab-content" id="testimonial-tabContent">
                <div className="tab-pane fade active show" id="testimonial-tab-1" role="tabpanel">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-lg-7 col-md-6">
                      <div className="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                        <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width={65} className="img-fluid mb-32" />
                        <div className="blockquote-title-review mb-4">
                          <h1 className="mb-0 h1 fw-semi-bold">Quality staffing Resources</h1>
                        </div>
                        <blockquote className="blockquote">
                          <p>Be it a startup or an enterprise, we’ll take care of your staffing needs.</p>
                        </blockquote>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6">
                      <div className="author-img-wrap pt-5 ps-5">
                        <div className="position-relative">
                          <img src="assets/img/innovations8.png" className="img-fluid" alt="testimonial author" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="feature-section-two ptb-60 bg-light">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 col-md-12">
              <div className="section-heading aos-init aos-animate" data-aos="fade-up">
                <h2>Staffing&nbsp;and&nbsp;Outsourcing</h2>
                <h6 className="gr-text pb-1">That you can avail for your Projects</h6><h6>
                </h6><h5 className="pb-1">Get the best in class Software Development services from a team of experienced professionals</h5>
                <p>We at Mind IT provide the best in class Web App Development Services created using the latest
                  technology as per the industry standard within the budget limits given to us by our clients.
                </p>
              </div>
              <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                <li className="py-1">
                  <div className="d-flex about-icon-box align-items-center">
                    <div className="me-3">
                      <img src="assets/img/pie-chart.png" alt />
                    </div>
                    <div>
                      <h5>Custom Web Development</h5>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div className="d-flex about-icon-box align-items-center">
                    <div className="me-3">
                      <img src="assets/img/team.png" alt />
                    </div>
                    <div>
                      <h5>ECommerce Web Development</h5>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div className="d-flex about-icon-box align-items-center">
                    <div className="me-3">
                      <img src="assets/img/team.png" alt />
                    </div>
                    <div>
                      <h5>CMS Web Development</h5>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div className="d-flex about-icon-box align-items-center">
                    <div className="me-3">
                      <img src="assets/img/team.png" alt />
                    </div>
                    <div>
                      <h5>Admin Panel Development</h5>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="counter-box mb-5 mb-lg-0">
                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <div className="single-counter-box bg-dark mb-3 mb-md-0">
                      <h5 className="h4">100+</h5>
                      <span>Technologies</span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="single-counter-box bg-dark mb-3 mb-md-0">
                      <h5 className="h4">15000+</h5>
                      <span>Successful Projects</span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="single-counter-box bg-dark">
                      <h5 className="h4">15+</h5>
                      <span>Years Experience</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-7">
              <div className="feature-img-wrap position-relative d-flex flex-column align-items-end">
                <img src="assets/img/screen/widget-8.png" alt="feature image" className="img-fluid rounded-custom" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-us ptb-60">
        <div className="container">
          <div className="row justify-content-lg-between align-items-center">
            <div className="col-lg-6 col-12">
              <div className="why-choose-content">
                <h2>Custom Web Development</h2>
                <h5 className="pb-1">Get the best in class Web App Development services from a team of experienced professionals</h5>
                <p className="text-justify">Distinctively promote parallel vortals with ubiquitous e-markets. Proactively benchmark turnkey
                  optimize next-generation strategic leadership without resource sucking ideas.
                  Distinctively promote parallel vortals with ubiquitous e-markets. Proactively benchmark turnkey
                  optimize next-generation strategic leadership without resource sucking ideas.
                </p>
                <h5 className="pb-1 gr-text">We are trusted by Technologies you believe in</h5>
                <ul className="list-unstyled d-flex flex-wrap mt-2 mb-1 pt-3">
                  <li className="py-1"><img height={50} src="assets/img/icons/html.png" /></li>
                  <li className="py-1 pl-20"><img height={50} src="assets/img/icons/css.png" /></li>
                  <li className="py-1 pl-20"><img height={50} src="assets/img/icons/js.png" /></li>
                  <li className="py-1 pl-20"><img height={50} src="assets/img/icons/react.png" /></li>
                  <li className="py-1 pl-20"><img height={50} src="assets/img/icons/angular.png" /></li>
                  <li className="py-1 pl-20"><img height={50} src="assets/img/icons/dotnet.png" /></li>
                  <li className="py-1 pl-20"><img height={50} src="assets/img/icons/sqlserver.png" /></li>
                  <li className="py-1 pl-20"><img height={50} src="assets/img/icons/oracledb.png" /></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="feature-img-holder mt-4 mt-lg-0 mt-xl-0">
                <img src="assets/img/service/webapp.jpg" className="img-fluid rounded-custom-page" alt="feature-image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="image-feature pt-60 pb-60">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 col-12 order-lg-1">
              <div className="feature-img-content">
                <h2>ECommerce&nbsp;Development</h2>
                <h5 className="pb-1">Get the best in class Web App Development services from a team of experienced professionals</h5>
                <p className="text-justify">Distinctively promote parallel vortals with ubiquitous e-markets. Proactively benchmark turnkey
                  optimize next-generation strategic leadership without resource sucking ideas.
                  Distinctively promote parallel vortals with ubiquitous e-markets. Proactively benchmark turnkey
                  optimize next-generation strategic leadership without resource sucking ideas.
                </p>
                <h5 className="pb-1 gr-text">We are trusted by Technologies you believe in</h5>
                <ul className="list-unstyled d-flex flex-wrap mt-2 mb-1 pt-3">
                  <li className="py-1"><img height={50} src="assets/img/icons/html.png" /></li>
                  <li className="py-1 pl-20"><img height={50} src="assets/img/icons/css.png" /></li>
                  <li className="py-1 pl-20"><img height={50} src="assets/img/icons/js.png" /></li>
                  <li className="py-1 pl-20"><img height={50} src="assets/img/icons/react.png" /></li>
                  <li className="py-1 pl-20"><img height={50} src="assets/img/icons/angular.png" /></li>
                  <li className="py-1 pl-20"><img height={50} src="assets/img/icons/dotnet.png" /></li>
                  <li className="py-1 pl-20"><img height={50} src="assets/img/icons/sqlserver.png" /></li>
                  <li className="py-1 pl-10"><img height={40} width={70} src="assets/img/icons/php.png" /></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-0">
              <div className="feature-img-holder mt-4 mt-lg-0 mt-xl-0">
                <img src="assets/img/service/ecommerce.jpg" className="img-fluid rounded-custom-page" alt="feature-image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-us pt-60 pb-60">
        <div className="container">
          <div className="row justify-content-lg-between align-items-center">
            <div className="col-lg-6 col-12">
              <div className="why-choose-content">
                <h2>CMS Web Development</h2>
                <h5 className="pb-1">Get the best in class Web App Development services from a team of experienced professionals</h5>
                <p>Distinctively promote parallel vortals with ubiquitous e-markets. Proactively benchmark turnkey optimize next-generation strategic leadership without resource sucking ideas.</p>
                <h5 className="gr-text">We are trusted by Technologies you believe in</h5>
                <ul className="list-unstyled d-flex flex-wrap mt-2 mb-1 pt-3">
                  <li className="py-1"><img height={50} src="assets/img/icons/wordpress.png" /></li>
                  <li className="py-1 pl-20"><img height={60} src="assets/img/icons/drupal.png" /></li>
                  <li className="py-1 pl-20"><img height={50} src="assets/img/icons/magento.png" /></li>
                  <li className="py-1 pl-20"><img height={60} src="assets/img/icons/shopify.png" /></li>
                  <li className="py-1 pl-20"><img height={60} src="assets/img/icons/opencart.png" /></li>
                  <li className="py-1"><img height={50} src="assets/img/icons/kentico.png" /></li>
                  <li className="py-1 pl-20"><img height={40} src="assets/img/icons/prestashop.png" /></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-12 order-1 order-lg-0">
              <div className="feature-img-holder mt-5 mt-lg-0 mt-xl-0">
                <img src="assets/img/service/cms2.jpg" className="img-fluid rounded-custom-page" alt="feature-image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cyber-features pt-60 bg-light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12">
              <div className="section-heading text-center mb-5">
                <h2>Our projects highlight the immense confidence our clients put in our work</h2>
                <p>
                  Here is a showcase of the latest projects our team has done for our clients using the latest
                  software technologies to capture Business Requirements and Solve business challenges through
                  the delivery of a multitude of IT Services and Solutions.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="our-project bg-white rounded-custom mb-30">
                <img height={190} width={320} src="assets/img/service/shop.jpg" />
                <div className="cyber feature-info-wrap">
                  <h3 className="h5 mt-3">Online Shopping</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="our-project bg-white rounded-custom mb-30">
                <img height={190} width={320} src="assets/img/service/maq.png" />
                <div className="cyber feature-info-wrap">
                  <h3 className="h5 mt-3">Enterprise Website</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="our-project bg-white rounded-custom mb-30">
                <img height={190} width={320} src="assets/img/tab-feature-img-5.png" />
                <div className="cyber feature-info-wrap">
                  <h3 className="h5 mt-3">Online Shopping</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="our-project bg-white rounded-custom mb-30">
                <img height={190} width={320} src="assets/img/service/efaindia.png" />
                <div className="cyber feature-info-wrap">
                  <h3 className="h5 mt-3">EFAIndia Dynamic Website</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="our-project bg-white rounded-custom mb-30">
                <img height={190} width={320} src="assets/img/service/edumaq.png" />
                <div className="cyber feature-info-wrap">
                  <h3 className="h5 mt-3">EDUMAQ Dynamic Website</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="our-project bg-white rounded-custom mb-30">
                <img height={190} width={320} src="assets/img/service/shop.jpg" />
                <div className="cyber feature-info-wrap">
                  <h3 className="h5 mt-3">Online Shopping</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cyber-cta pb-60">
        <div className="container">
          <div className="cyber-cta-bg p-5 rounded-custom" style={{ background: 'url("assets/img/cb_call_to_action.png") no-repeat center center / cover' }}>
            <div className="row">
              <div className="col-lg-8 col-md-9">
                <div className="cyber-cta-info position-relative">
                  <div className>
                    <h2 className="text-white">Are You Ready for Demo ? Schedule a Demo Now !</h2>
                    <p className="lead text-white mb-0">
                      Call Now : 09031010725 | 09031010726
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-5 col-6">
                <div className="cyber-cta-btn">
                  <a href="contactus.php" className="mt-3 btn btn-sm btn-primary me-auto">Schedule Demo <i className="far fa-arrow-right" /></a>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-5 col-6">
                <div className="cyber-cta-btn">
                  <a href="contactus.php" className="mt-3 btn btn-sm btn-danger me-auto">Explore more <i className="far fa-arrow-right" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}
