import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Footer() {
  return (
    <>
    {/*footer section start*/}
    <footer className="footer-section">
        {/*footer top start*/}
        {/*for light footer add .footer-light class and for dark footer add .bg-dark .text-white class*/}
        <div className="footer-top bg-dark text-white  ptb-120" style={{ background: 'url("assets/img/page-header-bg.svg")no-repeat bottom right', paddingBottom:'10px', paddingTop:'50px' }}>
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-md-8 col-lg-4 mb-md-4 mb-lg-0">
                        <div style={{marginTop:'-15px'}} className="footer-single-col">
                            <div className="footer-single-col mb-4">
                                <img style={{width:'80%'}} src="assets/img/logos/fornegologo.png" alt="logo" className="img-fluid logo-white" />
                                <img src="assets/img/FornegoLogo.png" alt="logo" className="img-fluid logo-color" />
                            </div>
                            <h4>Fornego Innovations Lab</h4>
                            <p>302 Sardha Second Floor, AECS LAYOUT 'A' Block.
                                Brookefield, Bengaluru , Karnataka <br/> Pin Code - 560037 (INDIA)
                            </p>
                            <p><i className="far fa-headset" /> 09031010725 , 9031010726</p>
                            <p><i className="far fa-envelope" /> info@fornego.com , fornegolabs@gmail.com</p>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0">
                        <div className="row">
                            <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                                <div className="footer-single-col">
                                    <h3>Technologies We Used</h3>
                                    <ul className="list-unstyled footer-nav-list mb-lg-0">
                                        <li><a href className="text-decoration-none">RFID, NFC and UHF</a></li>
                                        <li><a href className="text-decoration-none">GPS</a></li>
                                        <li><a href className="text-decoration-none">IOT and AI</a></li>
                                        <li><a href className="text-decoration-none">Cloud</a></li>
                                        <li><a href className="text-decoration-none">Smartcard and Tags</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                                <div className="footer-single-col">
                                    <h3>Our Products</h3>
                                    <ul className="list-unstyled footer-nav-list mb-lg-0">
                                        <li><a href className="text-decoration-none">School Management</a></li>
                                        <li><a href className="text-decoration-none">Hotel management</a></li>
                                        <li><a href className="text-decoration-none">Inventory and Billing</a></li>
                                        <li><a href className="text-decoration-none">POS Management</a></li>
                                        <li><a href className="text-decoration-none">HR &amp; Payroll</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                                <div className="footer-single-col">
                                    <h3>Quick Useful Links</h3>
                                    <ul className="list-unstyled footer-nav-list mb-lg-0">
                                        <li><a target="_blank" href="http://www.maqyuie.com/erp/admin/login" className="text-decoration-none">MAQYUIE ERP Login</a></li>
                                        <li><a target="_blank" href="https://sg2plcpnl0029.prod.sin2.secureserver.net:2096/" className="text-decoration-none">WebMail Login</a></li>
                                        {/* <li><a target="_blank" href="https://www.app.edumaq.in/00100" className="text-decoration-none">Edumaq Login</a></li> */}
                                        {/* <li><a target="_blank" href="https://portal.azure.com/#home" className="text-decoration-none">Azure Cloud Login</a></li> */}
                                        <li><a target="_blank" href="https://console.aws.amazon.com/console/home?nc2=h_ct&src=header-signin" className="text-decoration-none">AWS Cloud Login</a></li>
                                        <li><NavLink to="/privacy" className="nav-link">Privacy Policy</NavLink></li>
                                        <li><NavLink to="/termsconditions" className="nav-link">Terms Of Service</NavLink></li>
                                        <li><NavLink to="/refund" className="nav-link">Refund & Cancellation</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*footer top end*/}
        {/*footer bottom start*/}
        <div className="footer-bottom bg-dark text-white  py-4">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-md-7 col-lg-7">
                        <div className="copyright-text">
                            <p className="mb-lg-0 mb-md-0">© 2023 All Right Reserved | <a href="www.fornego.com"  className="text-decoration-none">FORNEGO INNOVATIONS LAB</a></p>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                        <div className="footer-single-col text-start text-lg-end text-md-end">
                            <ul className="list-unstyled list-inline footer-social-list mb-0">
                                <li className="list-inline-item"><a target="_blank" href="https://www.facebook.com/maqyuie"><i className="fab fa-facebook-f" /></a></li>
                                <li className="list-inline-item"><a target="_blank" href="https://www.instagram.com/maqyue"><i className="fab fa-instagram" /></a></li>
                                <li className="list-inline-item"><a href="#"><i className="fab fa-dribbble" /></a></li>
                                <li className="list-inline-item"><a href="#"><i className="fab fa-github" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*footer bottom end*/}
    </footer>
</>
  )
}
