import React from 'react'

export default function Refund() {
    return (
        <div classNamename="main-wrapper">
            <section className="customer-review-tab pt-75 pb-3 bg-gradient text-white  position-relative z-2">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="tab-content" id="testimonial-tabContent">
                                <div className="tab-pane fade active show" id="testimonial-tab-1" role="tabpanel">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-lg-7 col-md-7 col-12">
                                            <div className="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                                                <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width={65} className="img-fluid mb-32" />
                                                <div className="blockquote-title-review mb-4">
                                                    <h1 className="mb-0 h1 fw-semi-bold">Refund and Cancellation Policy</h1>
                                                </div>
                                                <blockquote className="blockquote">
                                                    <p>Order Cancellation, Refund and Return Policy</p>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-md-6 col-12">
                                            <div className="author-img-wrap pt-5 ps-5">
                                                <div className="position-relative">
                                                    <img src="assets/img/innovations8.png" className="img-fluid" alt="testimonial author" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="feature-section pt-4">
                <div class="container">
                    <div class="row align-items-lg-center justify-content-between">
                        <h5>LAST UPDATED: December 05, 2023</h5>
                        <p className='text-justify'><b>Fornego reserves the right to refuse/cancel any order. Fornego at its sole discretion may cancel any order(s):</b></p>
                        <p className='text-justify'>(1) If it suspects a fraudulent transaction, or</p>
                        <p className='text-justify'>(2) If it suspects a customer has undertaken a transaction which is not in accordance with the Terms of Use or</p>
                        <p className='text-justify'>(3) For any reason outside the control of the Fornego including causes for delivery related logistical difficulties.</p>
                        
                        <p className='text-justify'><b>Refund/cancellation policies applicable in the following conditions:</b></p>
                        <p className='text-justify'>(1) In case, the buyer cancels the order online before the product has been shipped, the entire order amount will be refunded.</p>
                        <p className='text-justify'>(2) In case the item ordered has been shipped but has not yet been delivered to the buyer, the
                            order can still be cancelled online. Total order amount after deduction of shipment and
                            handling charges will be refunded. The refund will be processed, once Fornego receives the
                            originally ordered item back intact from the courier.</p>
                        <p className='text-justify'>(3) However, the order once delivered cannot be cancelled in any case.</p>
                        <p className='text-justify'>(4) In case there is an option for online download of data than cancellation will not be possible</p>
                        <p className='text-justify'>(5) In case of failed transactions or double realization of account for the same order, the total deducted amount will be refunded.</p>
                        <p className='text-justify'>(6) In case of cancelled order/failed transactions, the bank/card transaction charges of the buyer, if any, is likely to be forfeited</p>
                        <p className='text-justify'>(7) Fornego offers no guarantees whatsoever for the accuracy or timeliness of the refunds in the buyers card/account </p>
                        <p className='text-justify'>(8) In case of part cancellations, the amount refunded will be corresponding to the part cancellation</p>
                   
                    </div>
                </div>
            </section>
            {/*?php include("common/footer.php") ?*/}
        </div>

    )
}

