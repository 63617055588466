import React from 'react'

export default function ContactUs() {
  return (
<div classname="main-wrapper">
  <section className="customer-review-tab pt-75 pb-3 bg-gradient text-white  position-relative z-2">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="tab-content" id="testimonial-tabContent">
            <div className="tab-pane fade active show" id="testimonial-tab-1" role="tabpanel">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6 col-md-6">
                  <div className="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                    <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width={65} className="img-fluid mb-32" />
                    <div className="blockquote-title-review mb-4">
                      <h1 className="mb-0 h1 fw-semi-bold">Talk&nbsp;to&nbsp;our&nbsp;sales&nbsp;&&nbsp;marketing department team</h1>
                    </div>
                    <blockquote className="blockquote">
                      <p>Globally network long-term high-impact schemas vis-a-vis distinctive
                        e-commerce</p>
                    </blockquote>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  <div className="author-img-wrap pt-5 ps-5">
                    <div className="position-relative">
                      <img src="assets/img/innovations8.png" className="img-fluid rounded-custom shadow-lg" alt="testimonial author" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="contact-us-form ptb-60" style={{background: 'url("assets/img/shape/contact-us-bg.svg")no-repeat center bottom'}}>
    <div className="container">
      <div className="row justify-content-lg-between align-items-center">
        <div className="col-lg-7 col-md-8">
          <div className="section-heading">
            <h3>SEND US A MESSAGE</h3>
            <p>We love to hear from you.Fill out the form below with some info about your project and I will get back to you as soon as I can. Please allow a few hours for me to respond.</p>
          </div>
          <form action="#" className="register-form">
            <div className="row">
              <div className="col-sm-6">
                <label htmlFor="firstName" className="mb-1">First name <span className="text-danger">*</span></label>
                <div className="input-group mb-3">
                  <input type="text" className="form-control" id="firstName" required placeholder="First name" aria-label="First name" />
                </div>
              </div>
              <div className="col-sm-6 ">
                <label htmlFor="lastName" className="mb-1">Last name</label>
                <div className="input-group mb-3">
                  <input type="text" className="form-control" id="lastName" placeholder="Last name" aria-label="Last name" />
                </div>
              </div>
              <div className="col-sm-6">
                <label htmlFor="phone" className="mb-1">Phone <span className="text-danger">*</span></label>
                <div className="input-group mb-3">
                  <input type="text" className="form-control" id="phone" required placeholder="Phone" aria-label="Phone" />
                </div>
              </div>
              <div className="col-sm-6">
                <label htmlFor="email" className="mb-1">Email<span className="text-danger">*</span></label>
                <div className="input-group mb-3">
                  <input type="email" className="form-control" id="email" required placeholder="Email" aria-label="Email" />
                </div>
              </div>
              <div className="col-12">
                <label htmlFor="yourMessage" className="mb-1">Message <span className="text-danger">*</span></label>
                <div className="input-group mb-3">
                  <textarea className="form-control" id="yourMessage" required placeholder="How can we help you?" style={{height: 120}} defaultValue={""} />
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-success btn-sm mt-4">Submit Enqiry</button>
          </form>
        </div>
        <div class="col-md-1 col-lg-1"></div>
        <div class="col-md-4 col-lg-4">
          <div class="footer-single-col">
                <div class="footer-single-col mb-4">
                  <h4>Fornego Innovations Lab</h4>
                  <b>302 Sardha Second Floor, AECS LAYOUT <br/> A Block. Brookefield, Bengaluru<br/> Karnataka , Pin Code - 560037 (INDIA)</b>
                  <p className='mt-3'><i class="far fa-headset"></i> 09031010725 (Sales)</p>
                  <p><i class="far fa-headset"></i> 09031010726 (Support)</p>
                  <p><i class="far fa-envelope"></i> info@fornego.com , fornegolabs@gmail.com</p>
                </div>
          </div>
          <div class="footer-single-col">
                <div class="footer-single-col mb-4">
                  <h4>Fornego Innovations Lab</h4>
                  <b>Nirmal Nagar , Bank Colony , Adityapur<br/> Jamshedpur , Jharkhand , Pin Code - 831013 (INDIA)</b>
                  <p className='mt-3'><i class="far fa-headset"></i> 09031010725</p>
                  <p><i class="far fa-envelope"></i> jamshedpur@fornego.com</p>
                </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* <section className="contact-promo ptb-60">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
          <div className="contact-us-promo p-4 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100">
            <div className="contact-promo-info mb-4">
              <h4>Jamshedpur (Jharkhand)</h4>
              <p>We've got live Social Experts waiting to help you <strong>monday to friday</strong> from
                <strong>9am to 5pm EST.</strong>
              </p>
            </div>
            <a href="mailto:hellothemetags@gmail.com" className="btn btn-link mt-auto">Chat with us</a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
          <div className="contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100">
            <span className="fad fa-envelope fa-3x text-primary" />
            <div className="contact-promo-info mb-4">
              <h5>Email Us</h5>
              <p>Simple drop us an email at <strong>hellothemetags@gmail.com</strong>
                and you'll receive a reply within 24 hours</p>
            </div>
            <a href="mailto:hellothemetags@gmail.com" className="btn btn-primary mt-auto">Email Us</a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
          <div className="contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100">
            <span className="fad fa-phone fa-3x text-primary" />
            <div className="contact-promo-info mb-4">
              <h5>Give us a call</h5>
              <p>Give us a ring.Our Experts are standing by <strong>monday to friday</strong> from
                <strong>9am to 5pm EST.</strong>
              </p>
            </div>
            <a href="tel:00-976-561-008" className="btn btn-link mt-auto">00-976-561-008</a>
          </div>
        </div>
      </div>
    </div>
  </section> */}
</div>

  )
}
