import React from 'react'

export default function Demo() {
    return (
        <div classname="main-wrapper">
            <section className="customer-review-tab pt-75 pb-3 bg-gradient text-white  position-relative z-2">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="tab-content" id="testimonial-tabContent">
                                <div className="tab-pane fade active show" id="testimonial-tab-1" role="tabpanel">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-lg-7 col-md-7">
                                            <div className="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                                                <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width={65} className="img-fluid mb-32" />
                                                <div className="blockquote-title-review mb-4">
                                                    <h1 className="mb-0 h1 fw-semi-bold">Request a product or service Demo</h1>
                                                </div>
                                                <blockquote className="blockquote">
                                                    <p>Request a demo today to see how our privacy management software can help your organization operationalize compliance and privacy by design.</p>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-md-6">
                                            <div className="author-img-wrap pt-5 ps-5">
                                                <div className="position-relative">
                                                    <img src="assets/img/innovations8.png" className="img-fluid" alt="testimonial author" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sign-up-in-section ptb-60">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-5 col-lg-5 col-md-12 order-1 order-lg-0">
                        <img className="img-fluid mb-32" src="assets/img/tab_img2.png" />
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-12 order-0 order-lg-1">
                            <div className="register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0">
                                <h3 className="fw-medium h4">Fill out the form and we'll be in touch as soon as possible.</h3>
                                <form action="#" className="mt-4 register-form">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" placeholder="Name" aria-label="name" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="input-group mb-3">
                                                <input type="mobile" className="form-control" placeholder="Mobile No" aria-label="Mobile No" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-group mb-3">
                                                <input type="email" className="form-control" placeholder="Work email" aria-label="work-Email" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" placeholder="Company website" aria-label="company-website" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-group mb-3">
                                                <select className="form-control form-select" name="budget" id="budget" required data-msg="Please select your budget.">
                                                    <option value selected disabled>Budget</option>
                                                    <option value="budget1">None, just getting started</option>
                                                    <option value="budget1">Less than ₹ 250000</option>
                                                    <option value="budget1">₹ 20,000 to ₹ 50,000</option>
                                                    <option value="budget1">₹ 50,000 to ₹ 100,000</option>
                                                    <option value="budget2">₹ 100,000 to ₹ 500,000</option>
                                                    <option value="budget3">More than ₹ 500,000</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" placeholder="Location" aria-label="Location" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group mb-3">
                                                <textarea className="form-control" placeholder="Tell us more about your project, needs and budget" style={{ height: 120 }} defaultValue={""} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckChecked" />
                                                <label className="form-check-label small" htmlFor="flexCheckChecked">
                                                    Yes, I'd like to receive occasional marketing emails from us. I have the
                                                    right to opt out at any time.
                                                    <a href="#"> View privacy policy</a>.
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button type="submit" className="btn btn-primary mt-4 d-block w-100">Get Started
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*?php include("common/footer.php") ?*/}
        </div>

    )
}
