import React from 'react'

export default function ERP() {
  return (
      <div classname="main-wrapper">
          <section className="customer-review-tab pt-75 pb-3 bg-gradient text-white  position-relative z-2">
              <div className="container">
                  <div className="row">
                      <div className="col-12">
                          <div className="tab-content" id="testimonial-tabContent">
                              <div className="tab-pane fade active show" id="testimonial-tab-1" role="tabpanel">
                                  <div className="row align-items-center justify-content-between">
                                      <div className="col-lg-7 col-md-6">
                                          <div className="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                                              <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width={65} className="img-fluid mb-32" />
                                              <div className="blockquote-title-review mb-4">
                                                  <h1 className="mb-0 h1 fw-semi-bold">Best Hotel management software</h1>
                                
                                              </div>
                                              <blockquote className="blockquote">
                                                  <p>Be it a startup or an enterprise, we’ll take care of your Hotel management needs.</p>
                                              </blockquote>
                                          </div>
                                      </div>
                                      <div className="col-lg-5 col-md-6">
                                          <div className="author-img-wrap pt-5 ps-5">
                                              <div className="position-relative">
                                                  <img src="assets/img/innovations8.png" className="img-fluid" alt="testimonial author" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="feature-section-two ptb-60 bg-light">
              <div className="container">
                  <div className="row align-items-center justify-content-between">
                      <div className="col-lg-6 col-md-12">
                          <div className="section-heading aos-init aos-animate" data-aos="fade-up">
                              <h2>Hotel Pro</h2>
                              <h3>Smart&nbsp;and&nbsp;User&nbsp;frindly&nbsp;Hotel Management System</h3>
                              <p className="text-justify">
                                  Edumaq is one of India's pioneers in developing fully automated ERP solutions for schools
                                  and colleges Developed by <b>MAQYUIE SOLUTIONS PVT LTD</b>. It is Powerful, reliable and affordable Software for managing the end-to-end processes
                                  of digital education system. The platform comes with Student
                              </p>
                          </div>
                          <div className="action-btn mt-5 mb-5 align-items-center d-block d-sm-flex d-lg-flex d-md-flex">
                              <a href="#" className="btn btn-sm btn-danger me-3">Request for Demo</a>
                              <a href="portfolio.php" className="btn btn-sm btn-primary me-3">Login Demo</a>
                              <a href="https://www.youtube.com/watch?v=qXxvyLBlmR0" className="text-decoration-none popup-youtube d-inline-flex align-items-center watch-now-btn mt-3 mt-lg-0 mt-md-0">
                                  <i className="fas fa-play gr-text" /> Watch Demo
                              </a>
                          </div>
                          <div className="counter-box mb-5 mb-lg-0">
                              <div className="row">
                                  <div className="col-lg-4 col-md-4">
                                      <div className="single-counter-box bg-dark mb-3 mb-md-0">
                                          <h5 className="h4">10 +</h5>
                                          <span>Countries</span>
                                      </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                      <div className="single-counter-box bg-dark mb-3 mb-md-0">
                                          <h5 className="h4">5000 +</h5>
                                          <span>Happy Customers</span>
                                      </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                      <div className="single-counter-box bg-dark">
                                          <h5 className="h4">5000000 +</h5>
                                          <span>Onboard Users</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-6 col-md-7">
                          <div className="feature-img-wrap position-relative d-flex flex-column align-items-end">
                              <img  src="assets/img/dash.jpg" alt="feature image" className="img-fluid rounded-custom" />
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="feature-section-two pt-60">
              <div className="container">
                  <div className="row align-items-center justify-content-lg-between justify-content-center">
                      <div className="col-lg-8 col-md-12">
                          <div className="feature-content-wrap">
                              <div className="section-heading">
                                  <h2>Hotel Pro Advantages</h2>
                                  <p>Quickly incubate functional channels with multidisciplinary architectures authoritatively
                                      fabricate formulate exceptional innovation.</p>
                              </div>
                              <div className="app-two-feature-two-content">
                                  <ul className="list-unstyled d-flex flex-wrap list-two-col mt-5 aos-init aos-animate" data-aos="fade-up">
                                      <li className="d-flex align-items-start mb-4">
                                          <div className="icon-box bg-danger .rounded-3 me-4">
                                              <i className="fas fa-fingerprint text-white" />
                                          </div>
                                          <div className="icon-content">
                                              <h3 className="h5">Unique &amp; Minimal Design1</h3>
                                              <p>Dramatically administrate progressive metrics
                                              </p>
                                          </div>
                                      </li>
                                      <li className="d-flex align-items-start mb-4">
                                          <div className="icon-box bg-danger rounded me-4">
                                              <i className="fas fa-fingerprint text-white" />
                                          </div>
                                          <div className="icon-content">
                                              <h3 className="h5">Unique &amp; Minimal Design</h3>
                                              <p>Dramatically administrate progressive metrics
                                              </p>
                                          </div>
                                      </li>
                                      <li className="d-flex align-items-start mb-4">
                                          <div className="icon-box bg-danger rounded me-4">
                                              <i className="fas fa-fingerprint text-white" />
                                          </div>
                                          <div className="icon-content">
                                              <h3 className="h5">Unique &amp; Minimal Design</h3>
                                              <p>Dramatically administrate progressive metrics
                                              </p>
                                          </div>
                                      </li>
                                      <li className="d-flex align-items-start mb-4">
                                          <div className="icon-box bg-danger rounded me-4">
                                              <i className="fas fa-fingerprint text-white" />
                                          </div>
                                          <div className="icon-content">
                                              <h3 className="h5">Unique &amp; Minimal Design</h3>
                                              <p>Dramatically administrate progressive metrics
                                              </p>
                                          </div>
                                      </li>
                                      <li className="d-flex align-items-start mb-4">
                                          <div className="icon-box bg-danger rounded me-4">
                                              <i className="fas fa-fingerprint text-white" />
                                          </div>
                                          <div className="icon-content">
                                              <h3 className="h5">Unique &amp; Minimal Design</h3>
                                              <p>Dramatically administrate progressive metrics
                                              </p>
                                          </div>
                                      </li>
                                      <li className="d-flex align-items-start mb-4">
                                          <div className="icon-box bg-danger rounded me-4">
                                              <i className="fas fa-fingerprint text-white" />
                                          </div>
                                          <div className="icon-content">
                                              <h3 className="h5">Unique &amp; Minimal Design</h3>
                                              <p>Dramatically administrate progressive metrics
                                              </p>
                                          </div>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-7">
                          <div className="feature-img-wrap">
                              <img src="assets/img/hms.png" alt="feature image" className="img-fluid rounded-custom" />
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="feature-section pt-60">
              <div className="container">
                  <div className="row align-items-lg-center justify-content-between">
                      <div className="col-lg-7 order-lg-1">
                          <div style={{ marginTop: '-50px' }} className="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                              {/*animated shape start*/}
                              {/*animated shape end*/}
                              <div className="bg-light w-100 text-center rounded-custom overflow-hidden pt-5 px-3  mx-lg-auto">
                                  <img className="position-relative z-2 w-100 h-auto pb-3" src="assets/img/hotelDashboard.png" alt="Image Description" />
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-5 mb-7 mb-lg-0 pt-5 pt-lg-0">
                          <div className="mb-4 aos-init aos-animate" data-aos="fade-up">
                              <h3 style={{ marginBottom: '-1px' }}>Hotel Pro</h3>
                              <h2>Management Console</h2>
                              <h6 className="gr-text pb-1">Grow&nbsp;Your&nbsp;School&nbsp;With&nbsp;India's&nbsp;Most Trusted Advance School Management Software</h6>
                              <p className="text-justify">
                                  Run your school more efficiently by digitizing and automating daily tasks and improving parental involvement through better parent-teacher communication
                              </p>
                          </div>
                          <div className="app-two-feature-two-content">
                              <ul className="list-unstyled d-flex flex-wrap list-two-col mt-5 aos-init aos-animate" data-aos="fade-up">
                                  <li>
                                      <h3 className="h5">Humans on hand</h3>
                                      <p>Objectively exceptional via customized via expertise.</p>
                                  </li>
                                  <li>
                                      <h3 className="h5">Strong privacy</h3>
                                      <p>Interactively integrate extensible users resource. </p>
                                  </li>
                                  <li>
                                      <h3 className="h5">Humans on hand</h3>
                                      <p>Objectively exceptional via customized via expertise.</p>
                                  </li>
                                  <li>
                                      <h3 className="h5">Strong privacy</h3>
                                      <p>Interactively integrate extensible users resource. </p>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="feature-section ptb-60">
              <div className="container">
                  <div className="row align-items-lg-center justify-content-between">
                      <div className="col-lg-6 mb-7 mb-lg-0">
                          <div className="mb-4 aos-init aos-animate" data-aos="fade-up">
                              <h3 style={{ marginBottom: '-1px' }}>Hotel Pro</h3>
                              <h2>ProConnect - Your Digital Booking</h2>
                              <p>Enthusiastically administrate robust initiatives quickly unleash collaborative with client-focused paradigms. Re-engineer granular innovation rather than best-of-breed processes. </p>
                          </div>
                          <ul className="list-unstyled d-flex flex-wrap list-two-col mt-5 aos-init aos-animate" data-aos="fade-up">
                              <li>
                                  <h3 className="h5">Humans on hand</h3>
                                  <p>Objectively exceptional via customized via expertise.</p>
                              </li>
                              <li>
                                  <h3 className="h5">Strong privacy</h3>
                                  <p>Interactively integrate extensible users resource. </p>
                              </li>
                              <li>
                                  <h3 className="h5">Humans on hand</h3>
                                  <p>Objectively exceptional via customized via expertise.</p>
                              </li>
                              <li>
                                  <h3 className="h5">Strong privacy</h3>
                                  <p>Interactively integrate extensible users resource. </p>
                              </li>
                          </ul>
                      </div>
                      <div className="col-lg-6">
                          <div className="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                              <div className="bg-light text-center shadow-sm rounded-custom overflow-hidden pt-5 px-3 px-lg-5 mx-lg-auto">
                                  <div className="mb-5">
                                      <div className="position-relative w-100 mx-auto">
                                          <img style={{ float: 'left' }} className="position-relative z-2 w-50 h-auto" src="assets/img/46.jpg" alt="Image Description" />
                                          <img style={{ float: 'left' }} className="position-relative z-2 w-50 h-auto" src="assets/img/49.jpg" alt="Image Description" />
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="feature-section pt-60 pb-120">
              <div className="container">
                  <div className="row align-items-lg-center justify-content-between">
                      <div className="col-lg-6 order-lg-2 mb-7 mb-lg-0">
                          <div className="mb-4 aos-init aos-animate" data-aos="fade-up">
                              <h3 style={{ marginBottom: '-1px' }}>Hotel Pro</h3>
                              <h2>Responsive Website</h2>
                              <h4>An integrated Solutions for eLearning</h4>
                              <p>Enthusiastically administrate robust initiatives quickly unleash collaborative with client-focused paradigms. Re-engineer granular innovation rather than best-of-breed processes. </p>
                          </div>
                          <ul className="list-unstyled d-flex flex-wrap list-two-col mt-5 aos-init aos-animate" data-aos="fade-up">
                              <li>
                                  <h3 className="h5">Humans on hand</h3>
                                  <p>Objectively exceptional via customized via expertise.</p>
                              </li>
                              <li>
                                  <h3 className="h5">Strong privacy</h3>
                                  <p>Interactively integrate extensible users resource. </p>
                              </li>
                          </ul>
                      </div>
                      <div className="col-lg-6 order-lg-1">
                          <div className="pr-lg-4 position-relative aos-init aos-animate" data-aos="fade-up">
                              <div className="bg-light text-center rounded-custom overflow-hidden pt-5  mx-lg-auto">
                                  <div className="position-relative w-100 mx-auto">
                                      <img className="position-relative z-2 w-100 h-auto" src="assets/img/hotelwebsite2.png" alt="Image Description" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="home-blog-section ptb-60 bg-light ">
              <div className="container">
                  <div className="row align-items-center justify-content-between">
                      <div className="col-lg-12 col-md-12">
                          <div className="section-heading aos-init aos-animate" data-aos="fade-up">
                              <h2 className="text-center">More Related items by MAQYUIE SOLUTIONS PVT LTD</h2>
                              <h5 className="text-center">An integrated Solutions for eLearning</h5>
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-lg-4 col-md-6">
                          <div className="single-article rounded-custom mb-4 mb-lg-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay={50}>
                              <a href="#" className="article-imgq">
                                  <img src="assets/img/pathshala.png" alt="article" className="img-fluid" />
                              </a>
                              <div className="article-content p-4">
                                  <div className="article-category mb-4 d-block">
                                      <a href="javascript:;" className="d-inline-block text-dark badge bg-warning-soft">Free</a>
                                  </div>
                                  <a href="#">
                                      <h1 className="h3 article-title limit-2-line-text">Pathshala</h1>
                                  </a>
                                  <p className="limit-2-line-text">Lightweight and User Friendly Tool for managing School Daily Operations</p>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                          <div className="single-article rounded-custom mb-4 mb-lg-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay={100}>
                              <a href="b#" className="article-img11">
                                  <img src="assets/img/pathshala.png" alt="article" className="img-fluid" />
                              </a>
                              <div className="article-content p-4">
                                  <div className="article-category mb-4 d-block">
                                      <a href="javascript:;" className="d-inline-block text-dark badge bg-warning-soft">Free</a>
                                  </div>
                                  <a href="#">
                                      <h1 className="h3 article-title limit-2-line-text">Pathshala</h1>
                                  </a>
                                  <p className="limit-2-line-text">Lightweight and User Friendly Tool for managing School Daily Operations</p>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                          <div className="single-article rounded-custom mb-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay={150}>
                              <a href="#" className="article-img11">
                                  <img src="assets/img/pathshala.png" alt="article" className="img-fluid" />
                              </a>
                              <div className="article-content p-4">
                                  <div className="article-category mb-4 d-block">
                                      <a href="javascript:;" className="d-inline-block text-dark badge bg-warning-soft">Free</a>
                                  </div>
                                  <a href="#">
                                      <h1 className="h3 article-title limit-2-line-text">Pathshala</h1>
                                  </a>
                                  <p className="limit-2-line-text">Lightweight and User Friendly Tool for managing School Daily Operations</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>

  )
}
